import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';

import { SessionToken } from '../interfaces/sessiontoken';
import { constants } from '../../assets/constants';
import { LanguagesService } from '../services/languages.service';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private languagesService: LanguagesService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token: SessionToken = JSON.parse(localStorage.getItem(constants.keySession));
    if (token && token !== null && token.token !== null) {
      const authReq = req.clone({
        headers: req.headers.set('Authorization', 'Bearer ' + token.token)
        .set('language', this.languagesService.currentLanguage ? this.languagesService.currentLanguage : 'en-GB')
        .set('Cache-Control', 'no-cache')
        .set('Pragma', 'no-cache')
        .set('Expires', 'Sat, 01 Jan 2000 00:00:00 GMT')
      });
      
      return next.handle(authReq);
    }
    return next.handle(req);
  }
}
