import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { constants } from '../../assets/constants';
import { Task } from '../interfaces/task';

@Injectable()
export class TasksService {

  private urlGetTasks = constants.urlAPI + constants.endpoints.tasks.getList;
  private urlGetTask = constants.urlAPI + constants.endpoints.tasks.get;
  private urlSetTask = constants.urlAPI + constants.endpoints.tasks.put;

  constructor(public http: HttpClient) { }

  public getTasks(): Observable<any> {
    let currentDate = new Date();  
    const isoDate = new Date(currentDate.getTime() - (currentDate.getTimezoneOffset() * 60000)).toISOString().slice(0, -1);  
    const sendParams = {
      date_now: isoDate
    };
    return this.http.get(this.urlGetTasks, {params: sendParams});
  }

  public getTask(id: number): Observable<any> {
    return this.http.get(this.urlGetTask.replace(':id', id.toString()));
  }

  public putTask(task: Task): Observable<any> {
    const data = task;
    return this.http.put(this.urlSetTask, data);
  }
}
