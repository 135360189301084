import { Component, OnInit, Input, ViewChild, HostListener, AfterViewInit } from '@angular/core';
import { GlobalService } from '../../../services/global.service';
import { AuthService } from '../../../services/auth.service';
import { constants } from '../../../../assets/constants';
import { EventsService } from '../../../services/events.service';
import { MatDrawer } from '@angular/material/sidenav';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit, AfterViewInit {

  logged: boolean;

  submenus = { programs: false };

  @Input() drawer;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (window.innerWidth < 1000) {
      this.drawer.close();
    }
  }

  constructor(private globalService: GlobalService,
    private authService: AuthService,
    private eventsService: EventsService) {
    this.logged = false;
  }

  ngOnInit() {
    // this.checkPermissions();
    this.checkIsLogged();

    this.eventsService.event$.subscribe((params: any) => {
      if (params.key === 'login' && params.value === 'ok') {
        // this.checkPermissions();
        this.checkIsLogged();
      }
    });
    this.eventsService.event$.subscribe((params: any) => {
      if (params.key === 'logout' && params.value === 'ok') {
        // this.checkPermissions();
        this.logged = false;
      }
    });

  }

  // private checkPermissions() {
  //   this.canListUsers = this.authService.isInRole(constants.roles.ci_user_list);
  //   this.canListProfiles = this.authService.isInRole(constants.roles.ci_profile_list);
  // }

  ngAfterViewInit(){
    if (window.innerWidth < 1000) {
      this.drawer.close();
    }
  }

  private checkIsLogged() {
    this.authService.validateOrRefreshToken().subscribe(
      result => {
        this.logged = result.valid;
      });
  }

  toggleSubmenu(submenu: any) {
    this.submenus[submenu] = !this.submenus[submenu];
  }

}
