import { DMOrderDetailResolver } from './resolvers/dm-order-detail.resolver';
import { DocumentManagerDetailComponent } from './components/document-manager/document-manager-detail.component';
import { DocumentManagerListComponent } from './components/document-manager/document-manager-list.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UsersComponent } from './components/users/users.component';
import { UserDetailComponent } from './components/users/user-detail.component';
import { ProfilesComponent } from './components/profiles/profiles.component';
import { LoginComponent } from './components/login/login.component';
import { AuthGuard } from './guards/auth-guard';
import { constants } from '../assets/constants';
import { HomeComponent } from './components/home/home.component';
import { ProjectInfoComponent } from './components/project/info/info.component';
import { ProjectListComponent } from './components/project/project-list/project-list.component';
import { FormViewComponent } from './components/project/form-view/form-view.component';
import { FormManagerListComponent } from './components/form-manager/list/list.component';
import { FormManagerEditComponent } from './components/form-manager/edit/edit.component';
import { LogResolver } from './resolvers/log.resolver';
import { UserSettingsComponent } from './components/users/user-settings.component';
import { CustomerDetailComponent } from './components/users/customer-detail.component';
import { CustomersComponent } from './components/users/customers.component';
import { WelcomeComponent } from './components/welcome/welcome.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { RefreshComponent } from './components/common/refresh/refresh.component';
import { OrderListComponent } from './components/order/order-list/order-list.component';
import { SettingsComponent } from './components/settings/settings.component';

import { NotificationsResolver } from './resolvers/notifications.resolver';
import { UserSettingsResolver } from './resolvers/user-settings.resolver';
import { SalesTakersResolver } from './resolvers/salestakers.resolver';
import { InputTypesResolver } from './resolvers/input-types.resolver';
import { ProjectFormDataResolver } from './resolvers/project-form-data.resolver';
import { FormManagerListResolver } from './resolvers/form-manager-list.resolver';
import { FormManagerEditResolver } from './resolvers/form-manager-edit.resolver';
import { ProjectListResolver } from './resolvers/project-list.resolver';
import { ProjectInfoResolver } from './resolvers/project-info.resolver';
import { CustomerListResolver } from './resolvers/customer-list.resolver';
import { ChatResolver } from './resolvers/chat.resolver';
import { UserListResolver } from './resolvers/user-list.resolver';
import { UserDetailResolver } from './resolvers/user-detail.resolver';
import { GlobalResolver } from './resolvers/global.resolver';
import { ProfileListResolver } from './resolvers/profile-list.resolver';
import { PavigymCompaniesResolver } from './resolvers/pavicompanies.resolver';
import { FormsAvailableResolver } from './resolvers/forms-available.resolver';
import { DMOrderListResolver } from './resolvers/dm-order-list.resolver';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    resolve: { global: GlobalResolver },
    canActivate: [AuthGuard],
    data: { role: [constants.roles.cj_user] }
  },
  {
    path: 'welcome/:hash',
    component: WelcomeComponent,
    resolve: { }
  },
  {
    path: 'reset-password',
    component: ResetPasswordComponent,
    resolve: { }
  },
  {
    path: 'refresh',
    component: RefreshComponent,
    resolve: { }
  },
  {
    path: 'users',
    component: UsersComponent,
    resolve: { global: GlobalResolver, userList: UserListResolver },
    canActivate: [AuthGuard],
    data: { role: [constants.roles.cj_user_list] }
  },
  {
    path: 'user/settings',
    component: UserSettingsComponent,
    resolve: { user: UserSettingsResolver, global: GlobalResolver },
    canActivate: [AuthGuard],
    data: { role: [constants.roles.cj_user] }
  },
  {
    path: 'user/:id',
    component: UserDetailComponent,
    resolve: { user: UserDetailResolver, profileList: ProfileListResolver, global: GlobalResolver },
    canActivate: [AuthGuard],
    data: { role: [constants.roles.cj_user_detail] }
  },
  {
    path: 'user/new',
    component: UserDetailComponent,
    resolve: { global: GlobalResolver, user: UserDetailResolver, profileList: ProfileListResolver },
    canActivate: [AuthGuard],
    data: { role: [constants.roles.cj_user_create] }
  },
  {
    path: 'customers',
    component: CustomersComponent,
    resolve: { userList: CustomerListResolver },
    canActivate: [AuthGuard],
    data: { role: [constants.roles.cj_customer_list] }
  },
  {
    path: 'customer/:id',
    component: CustomerDetailComponent,
    resolve: { user: UserDetailResolver, global: GlobalResolver },
    canActivate: [AuthGuard],
    data: { role: [constants.roles.cj_customer_detail] }
  },
  {
    path: 'customer/new',
    component: CustomerDetailComponent,
    resolve: { global: GlobalResolver, user: UserDetailResolver, profileList: ProfileListResolver },
    canActivate: [AuthGuard],
    data: { role: [constants.roles.cj_customer_create] }
  },
  {
    path: 'profiles',
    component: ProfilesComponent,
    resolve: { profileList: ProfileListResolver, global: GlobalResolver },
    canActivate: [AuthGuard],
    data: { role: [constants.roles.cj_profile_list] }
  },
  {
    path: 'projects',
    component: ProjectListComponent,
    resolve: { global: GlobalResolver, projectList: ProjectListResolver, notifications: NotificationsResolver },
    canActivate: [AuthGuard],
    data: { role: [constants.roles.cj_tl_project_info_view] }
  },
  {
    path: 'project/:id',
    redirectTo: 'project/:id/info'
  },
  {
    path: 'project/:id/info',
    component: ProjectInfoComponent,
    resolve: {
      global: GlobalResolver,
      projectInfo: ProjectInfoResolver,
      customerList: CustomerListResolver,
      pavigymCompanies: PavigymCompaniesResolver,
      logMessages: LogResolver,
      chatMessages: ChatResolver,
      formsAvailable: FormsAvailableResolver,
      notifications: NotificationsResolver
    },
    canActivate: [AuthGuard],
    data: { role: [constants.roles.cj_tl_project_info_view] }
  },
  {
    path: 'project/:id/form/:fid',
    component: FormViewComponent,
    resolve: {
      global: GlobalResolver,
      // projectForm: ProjectFormResolver,
      projectInfo: ProjectInfoResolver,
      chatMessages: ChatResolver,
      logMessages: LogResolver,
      inputTypes: InputTypesResolver,
      projectFormData: ProjectFormDataResolver,
      formsAvailable: FormsAvailableResolver,
      notifications: NotificationsResolver
    },
    canActivate: [AuthGuard],
    data: { role: [constants.roles.cj_user] }
  },
  {
    path: 'form-manager/list',
    component: FormManagerListComponent,
    resolve: {
      global: GlobalResolver,
      formList: FormManagerListResolver
    },
    canActivate: [AuthGuard],
    data: { role: [constants.roles.cj_form_list] }
  },
  {
    path: 'form-manager/edit/:id',
    component: FormManagerEditComponent,
    resolve: {
      global: GlobalResolver,
      form: FormManagerEditResolver,
      inputTypes: InputTypesResolver,
      formList: FormManagerListResolver
    },
    canActivate: [AuthGuard],
    data: { role: [constants.roles.cj_form_detail] }
  },
  {
    path: 'orders',
    component: OrderListComponent,
    resolve: {
      global: GlobalResolver,
      notifications: NotificationsResolver,
      salesTakers: SalesTakersResolver
    },
    canActivate: [AuthGuard],
    data: { role: [constants.roles.cj_sales_order_info_view] }
  },
  {
    path: 'document-manager/orders',
    component: DocumentManagerListComponent,
    resolve: {
      orderList: DMOrderListResolver      
    },
    canActivate: [AuthGuard],
    data: { role: [constants.roles.cj_sales_order_info_view] }
  },
  {
    path: 'document-manager/order/:id',
    component: DocumentManagerDetailComponent,
    resolve: {
      order: DMOrderDetailResolver      
    },
    canActivate: [AuthGuard],
    data: { role: [constants.roles.cj_sales_order_items_detail] }
  },
  {
    path: 'settings',
    component: SettingsComponent,
    resolve: {  },
    canActivate: [AuthGuard],
    data: { role: [constants.roles.cj_sales_order_advanced_filter] }
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: '**', redirectTo: ''
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
