import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { GlobalService } from '../../../services/global.service';
import { AuthService } from '../../../services/auth.service';
import { ProjectForm } from '../../../interfaces/projectform';

@Component({
  selector: 'app-form-manager-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class FormManagerListComponent implements OnInit, AfterViewInit {

  formList: ProjectForm[];

  displayedColumns = ['id', 'name', 'actions'];
  dataSource = new MatTableDataSource;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  constructor(private route: ActivatedRoute,
    private translate: TranslateService,
    private globalService: GlobalService,
    private authService: AuthService) {
  }

  ngOnInit() {
    this.formList = this.route.snapshot.data.formList;

    this.translate.get('FORMS.TITLE').subscribe((translation) => {
      this.globalService.changePageTitle([translation]);
    });

  }

  ngAfterViewInit() {
    this.dataSource = new MatTableDataSource(this.formList);
    this.dataSource.paginator = this.paginator;
    this.dataSource.paginator.firstPage();
    this.dataSource.sort = this.sort;
  }

}
