import { Component, OnInit, Input } from '@angular/core';
import { LogMessage } from '../../../interfaces/logmessage';

@Component({
  selector: 'app-log-view',
  templateUrl: './log-view.component.html',
  styleUrls: ['./log-view.component.scss']
})
export class LogViewComponent implements OnInit {

  @Input() logMessages: LogMessage[];
  @Input() formId: number;

  filteredMessages: LogMessage[];

  constructor() { }

  ngOnInit() {
    if (this.logMessages !== undefined) {
      this.filteredMessages = this.logMessages.filter(msg => msg.log.formId === this.formId);
    } else {
      this.filteredMessages = [];
    }
  }

}
