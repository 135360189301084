import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { ActivatedRouteSnapshot } from '@angular/router';
import { CustomersService } from '../services/customers.service';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/of';

@Injectable()
export class CustomerListResolver implements Resolve<Observable<string>> {

    constructor(private customersService: CustomersService) { }

    resolve(route: ActivatedRouteSnapshot) {
        return this.customersService.getCustomers().catch(() => {
            return Observable.of('error');
        });

    }
}
