import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { constants } from '../../assets/constants';
import { ProjectForm } from '../interfaces/projectform';
import { Project } from '../interfaces/project';

@Injectable()
export class ProjectsService {

  private urlGetProjects = constants.urlAPI + constants.endpoints.projects.get;
  private urlGetProject = constants.urlAPI + constants.endpoints.project.get;
  private urlPutProject = constants.urlAPI + constants.endpoints.project.put;
  private urlPostProject = constants.urlAPI + constants.endpoints.project.post;
  private urlGetProductTypes = constants.urlAPI + constants.endpoints.project.getProductTypes;
  private urlGetPavigymCompanies = constants.urlAPI + constants.endpoints.project.getPavigymCompanies;

  constructor(public http: HttpClient) { }

  public getProjects(): Observable<any> {
    return this.http.get(this.urlGetProjects);
  }

  public getProject(id): Observable<any> {
    return this.http.get(this.urlGetProject.replace(':id', id));
  }

  public putProject(project: Project): Observable<any> {
    const data = project;
    return this.http.put(this.urlGetProjects, data);
  }

  public postProject(project: Project): Observable<any> {
    const data = project;
    return this.http.post(this.urlGetProjects, data);
  }

  // public deleteUser(id: number): Observable<any> {
  //   return this.http.delete(this.urlDeleteUser.replace(':id', id.toString()));
  // }

  // public getUserStatus(): Observable<any> {
  //   return this.http.get(this.urlGetUserStatus);
  // }

  public getProductTypes(): Observable<any> {
    return this.http.get(this.urlGetProductTypes);
  }

  public getPavigymCompanies(): Observable<any> {
    return this.http.get(this.urlGetPavigymCompanies);
  }
}
