import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { HttpClient } from '@angular/common/http';
import { constants } from '../../assets/constants';
import { WelcomeData } from '../interfaces/welcome';

@Injectable()
export class WelcomeService {

  private urlPutPassword = constants.urlAPI + constants.endpoints.user.putPassword;
  private urlGetWelcomeUser = constants.urlAPI + constants.endpoints.user.getWelcomeUser;

  constructor(public http: HttpClient) { }

  public putPassword(welcomeData: WelcomeData): Observable<any> {
    return this.http.put(this.urlPutPassword, welcomeData);
  }

  public getWelcomeUser(hash: string): Observable<any> {
    return this.http.get(this.urlGetWelcomeUser + '?hash=' + hash);
  }

}
