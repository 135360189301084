import { Pipe, PipeTransform } from '@angular/core';
import { GlobalService } from '../services/global.service';
import { Status } from '../interfaces/status';

@Pipe({
  name: 'userstatus'
})
export class UserStatusPipe implements PipeTransform {

  constructor(private globalService: GlobalService) { }

  transform(id: any): any {
    const userStatusList = this.globalService.getUserStatusList();
    if (userStatusList !== undefined) {
      return userStatusList.filter(sta => (sta.id === id))[0].name;
    }
  }

}
