import { Component, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GlobalService } from './services/global.service';
import { AuthService } from './services/auth.service';
import { ActivatedRoute } from '@angular/router';
import { MediaMatcher } from '@angular/cdk/layout';
import { LanguagesService } from './services/languages.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnDestroy {
  title = 'app';

  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;

  constructor(translate: TranslateService,
    private globalService: GlobalService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private languagesService: LanguagesService,
    changeDetectorRef: ChangeDetectorRef, media: MediaMatcher) {
    translate.setDefaultLang('en');
    translate.use(languagesService.getLanguage().substr(0,2));

    this.mobileQuery = media.matchMedia('(max-width: 800px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);

  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }



}
