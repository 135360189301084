import { Component, OnInit, Input } from '@angular/core';
import { UserNotification } from '../../../interfaces/notification';

@Component({
  selector: 'app-notification-list',
  templateUrl: './notification-list.component.html',
  styleUrls: ['./notification-list.component.scss']
})
export class NotificationListComponent implements OnInit {

  @Input() notificationList: UserNotification[];
  @Input() projectId?: Number;

  filteredNotifications: UserNotification[];

  constructor() { }

  ngOnInit() {
    if (this.projectId && this.notificationList !== undefined) {
      this.filteredNotifications = this.notificationList.filter(msg => msg.project && msg.project.id === this.projectId);
    } else {
      this.filteredNotifications = this.notificationList;
    }
  }

}
