import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginData } from '../../interfaces/logindata';
import { AuthService } from '../../services/auth.service';
import { constants } from '../../../assets/constants';
import { TranslateService } from '@ngx-translate/core';
import { ErrorStateMatcher } from '@angular/material/core';
import { FormControl, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { AlertsService } from '../../services/alerts.service';
import { EventsService } from '../../services/events.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  username: string;
  password: string;
  errorUser: boolean;
  errorPassword: boolean;

  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);

  matcher = new LoginErrorStateMatcher();

  constructor(private authService: AuthService,
    private router: Router,
    private translate: TranslateService,
    private alertsService: AlertsService,
    private eventsService: EventsService) {

    this.errorPassword = false;
    this.errorUser = false;
  }

  ngOnInit() {
  }

  onSubmit() {
    this.errorUser = this.errorPassword = false;

    if (!this.username || this.username === '') {
      this.errorUser = true;
    }
    if (!this.password || this.password === '') {
      this.errorPassword = true;
    }

    if (!this.errorUser && !this.errorPassword) {
      const data: LoginData = {
        email: this.username,
        password: this.password,
        appId: constants.appId
      };
      this.authService.checkLogin(data)
        .subscribe(resp => {
          this.authService.saveSession(resp);

          this.translate.get('LOGIN.LOGIN_OK').subscribe((translation) => {
            this.alertsService.alertTrigger({ msg: translation, button: 'Ok' });
            this.router.navigate(['']);

            this.eventsService.doEvent({ key: 'login', value: 'ok' });
          });
        }, error => {
          this.translate.get('LOGIN.INVALID_CREDENTIALS').subscribe((translation) => {
            this.alertsService.alertTrigger({ msg: translation, button: 'Ok' });
            this.password = this.username = '';
          });
        });
    }
  }
}

/** Error when invalid control is dirty, touched, or submitted. */
export class LoginErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}
