import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { HttpClient } from '@angular/common/http';
import { Profile } from '../interfaces/profile';
import { constants } from '../../assets/constants';

@Injectable()
export class CustomersService {

  private urlGetCustomers = constants.urlAPI + constants.endpoints.customers.get;

  constructor(public http: HttpClient) { }

  public getCustomers(): Observable<any> {
    return this.http.get(this.urlGetCustomers);
  }
 

}
