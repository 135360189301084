import { Component, OnInit, Output, EventEmitter, Input, ViewChild } from '@angular/core';
import { FilesService } from '../../../services/files.service';
import { ProjectFile } from '../../../interfaces/projectfile';
import { ProjectFormQuestion } from '../../../interfaces/projectform';

declare var UIkit: any;

@Component({
  selector: 'app-image-uploader',
  templateUrl: './image-uploader.component.html',
  styleUrls: ['./image-uploader.component.css']
})
export class ImageUploaderComponent implements OnInit {
  public dropOver = false;

  selectedImageName: string;
  localUrlImage: any;
  uploading: boolean;
  imageSelected: boolean;
  selectedImageFile: File;

  uploadedFiles: File[];
  uploadedFileNames: string[];
  alreadyUploaded: boolean;

  currentImage: string;

  // @Input() formField: IFormField;
  @Input() mediaUrl: string;
  @Input() imageName: string;
  @Input() files: ProjectFile[];
  @Input() oneFileOnly?: boolean;

  @Output() selectedImageEvent: EventEmitter<string> = new EventEmitter<string>();
  @Output() deletedImageEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  // @Input() editMode: boolean = true;

  @Output() saveForm = new EventEmitter();

  constructor(private filesService: FilesService) {
    this.selectedImageName = '';
    this.uploading = false;
    this.dropOver = false;
    this.imageSelected = false;
    this.uploadedFiles = [];
    this.uploadedFileNames = [];
    this.alreadyUploaded = false;
  }

  ngOnInit() {
    if (this.imageName && this.imageName !== '') {
      this.currentImage = this.mediaUrl + this.imageName + '?t=' + new Date().getTime();
      this.selectedImageName = this.imageName;
      this.imageSelected = true;
    }
  }

  dragEnter(event) {
    this.dropOver = true;
    event.preventDefault();
    event.stopPropagation();
  }

  dragLeave(event) {
    this.dropOver = false;
  }

  dragOver(event) {
    this.dropOver = true;
    event.preventDefault();
    event.stopPropagation();
  }

  dragEnd(event) {
  }

  dragStart(event) {
  }

  dropImage(event) {
    event.preventDefault();
    event.stopPropagation();
    if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {

      const files = event.dataTransfer.files;
      // this.previewImage(files);
      this.uploadSelectedImage(files);
    }
    this.dropOver = false;
  }

  selectImage(event) {
    if (event.target.files && event.target.files[0]) {
      // this.previewImage(event.target.files[0]);
      this.uploadSelectedImage(event.target.files);
    }
  }

  uploadSelectedImage(files) {
    this.uploading = true;
    this.uploadedFiles.push(...files);

    this.filesService.postFile(files)
      .subscribe(response => {
        this.imageSelected = true;
        this.uploading = false;

        this.uploadedFileNames.push(...Array.from(response));
        this.addFileObject(Array.from(response));
      },
        error => {
          console.error(`'Error uploading ${files.name}`);
          this.imageSelected = false;
          this.uploading = false;
        }
      );
  }

  addFileObject(fileNameArray: string[]) {
    fileNameArray.forEach((fileName, index) => {
      const newFile: ProjectFile = {
        id: 0,
        name: this.uploadedFiles[index].name,
        uniqueId: '',
        date: new Date().toISOString(),
        path: '',
        folder: null,
        type: '',
        fileName: fileName,
        description: '',
        owner: null
      };
      this.files.push(newFile);
    });
    this.saveForm.emit();
    this.uploadedFiles.length = 0;
    this.uploadedFileNames.length = 0;
    this.alreadyUploaded = true;
  }


  previewImage(file) {
    const reader = new FileReader();
    reader.onloadend = (event) => {
      this.localUrlImage = reader.result;
      this.selectedImageName = file.name;
      this.dropOver = false;
    };
    reader.onerror = (error) => {
      // this.notificationService.notify('Error reading image to display',
      //       ENUMS.notification.types.error,
      //       ENUMS.notification.positions.custom_responsive);
    };
    reader.readAsDataURL(file);
  }

  removeSelection() {
    this.uploading = false;
    this.localUrlImage = [];
    this.selectedImageName = '';
    this.imageSelected = false;
    this.deletedImageEvent.emit(true);
  }
}

