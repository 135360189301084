import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { HttpClient } from '@angular/common/http';
import { constants } from '../../assets/constants';
import { ProjectForm, ProjectFormLayout } from '../interfaces/projectform';

@Injectable()
export class FormManagerService {

  private urlGetForms = constants.urlAPI + constants.endpoints.forms.get;
  private urlGetForm = constants.urlAPI + constants.endpoints.form.get;
  private urlPostForm = constants.urlAPI + constants.endpoints.form.post;
  private urlPutForm = constants.urlAPI + constants.endpoints.form.put;

  constructor(public http: HttpClient) { }

  public getForms(): Observable<any> {
    return this.http.get(this.urlGetForms);
  }

  public getForm(id): Observable<any> {
    return this.http.get(this.urlGetForm.replace(':id', id.toString()));
  }

  public putForm(form: ProjectFormLayout): Observable<any> {
    const data = form;
    return this.http.put(this.urlPutForm, data);
  }

  public postForm(form: ProjectFormLayout): Observable<any> {
    const data = form;
    return this.http.post(this.urlPostForm, data);
  }

}
