import { Component, OnInit, Input, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { ChatMessage } from '../../../interfaces/chatmessage';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../../../services/auth.service';
import { ChatService } from '../../../services/chat.service';
import { ProjectsService } from '../../../services/projects.service';
import { Project } from '../../../interfaces/project';
import { DomSanitizer } from '@angular/platform-browser';
import { constants } from '../../../../assets/constants';
import { EventsService } from '../../../services/events.service';
import { GlobalService } from '../../../services/global.service';

@Component({
  selector: 'app-mainchat',
  templateUrl: './mainchat.component.html',
  styleUrls: ['./mainchat.component.scss']
})
export class MainchatComponent implements OnInit, AfterViewInit, OnDestroy {

  chatMessages: ChatMessage[];
  username: string;
  @Input() project: Project;
  messageText: string;
  chatRefreshInterval;

  @ViewChild('messageList', { static: true }) messageList;

  constructor(private route: ActivatedRoute,
    public authService: AuthService,
    private chatService: ChatService,
    private projectsService: ProjectsService,
    private sanitizer: DomSanitizer,
    private eventsService: EventsService,
    public globalService: GlobalService) { }

  ngOnInit() {
    this.chatMessages = this.route.snapshot.data.chatMessages;

    this.eventsService.event$.subscribe((params: any) => {
      if (params.key === 'newChat') {
        if (params.value === 'true') {
          this.refreshChat();
        }
      }
    });
  }

  ngAfterViewInit() {
    this.scrollToBottom();
  }

  ngOnDestroy() {
    clearInterval(this.chatRefreshInterval);
  }

  public sendMessage() {
    const newMessage: ChatMessage = {
      id: 0,
      creationDate: new Date().toUTCString(),
      creator: null,
      project: { id: this.project.id },
      text: this.messageText
    };

    this.chatService.postChat(newMessage).subscribe(
      postResult => {
        this.refreshChat();
      },
      error => {
        console.log('Error', error);
      }
    );
    this.messageText = '';
    this.scrollToBottom();
  }

  public refreshChat() {
    this.chatService.getChat(this.project.id).subscribe(getResult => {
      if (this.chatMessages === undefined || this.chatMessages.length !== getResult.length) {
        this.chatMessages = getResult;
        setTimeout(() => {
          this.scrollToBottom();
        }, 50);
      }
    });
  }

  private scrollToBottom() {
    this.messageList.nativeElement.scrollTop = this.messageList.nativeElement.scrollHeight;
  }

  public getAvatarUrl(creator: any) {
    if (creator.avatar) {
      return constants.urlUserAvatar.replace(':id', creator.id.toString()).replace(':file', creator.avatar);
    } else {
      return;
    }

  }
}
