import { Component, OnInit, TemplateRef, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { GlobalService } from '../../../services/global.service';
import { TranslateService } from '@ngx-translate/core';
import { AlertsService } from '../../../services/alerts.service';

@Component({
    selector: 'app-modal-validate-form',
    templateUrl: 'modal-validate-form.html',
})
export class ModalValidateFormComponent {

    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<ModalValidateFormComponent>,
        private globalService: GlobalService,
        private router: Router,
        private translate: TranslateService,
        private alertsService: AlertsService) {
    }

    modalCancel() {
        this.dialogRef.close();
    }

}
