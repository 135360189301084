import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/of';
import { FormManagerService } from '../services/formmanager.service';

@Injectable()
export class FormManagerListResolver implements Resolve<Observable<string>> {

    constructor(private formManagerService: FormManagerService) { }

    resolve() {
        return this.formManagerService.getForms().catch(() => {
            return Observable.of('error');
        });
    }
}
