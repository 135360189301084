import { Component, OnInit, AfterViewInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { ProjectFormsService } from '../../../services/projectforms.service';
import { TranslateService } from '@ngx-translate/core';
import { AlertsService } from '../../../services/alerts.service';
import { LogMessage } from '../../../interfaces/logmessage';
import { Project } from '../../../interfaces/project';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalService } from '../../../services/global.service';
import { AuthService } from '../../../services/auth.service';
import { Customer } from '../../../interfaces/customer';
import { constants } from '../../../../assets/constants';
import { ProjectFormLanguage } from '../../../interfaces/projectform';
import { PavigymCompany } from '../../../interfaces/pavigym-company';
import { UserNotification } from '../../../interfaces/notification';
import { LoadingService } from '../../../services/loading.service';
import { NotificationsService } from '../../../services/notifications.service';
import { EventsService } from '../../../services/events.service';
import { ProjectsService } from '../../../services/projects.service';

import { MediaMatcher } from '@angular/cdk/layout';
import { TutorialService } from '../../../services/tutorial.service';

@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss']
})

export class ProjectInfoComponent implements OnInit, AfterViewInit, OnDestroy {

  public logMessages: LogMessage[];
  public notificationList: UserNotification[];
  public project: Project = {
    id: 0,
    name: '',
    annotations: '',
    company: '',
    location: '',
    address: '',
    city: '',
    country: '',
    contactEmail: '',
    contactName: '',
    contactSurnames: '',
    contactTelephone: '',
    creator: null,
    customer: null,
    language: null,
    order: '',
    status: null,
    productType: null
  };

  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;

  public customerList: Customer[];
  public pavigymCompanies: PavigymCompany[];

  public languages: ProjectFormLanguage[];

  isNew: Boolean = false;

  timelineMenuVisible: boolean;
  messagesVisible: boolean;
  ganttPanelOpened: boolean;

  constructor(private route: ActivatedRoute,
    private router: Router,
    private projectFormsService: ProjectFormsService,
    private projectsService: ProjectsService,
    private translate: TranslateService,
    private alertsService: AlertsService,
    public authService: AuthService,
    private loadingService: LoadingService,
    public notificationsService: NotificationsService,
    public globalService: GlobalService,
    public tutorialService: TutorialService,
    private eventsService: EventsService,
    changeDetectorRef: ChangeDetectorRef, media: MediaMatcher) {

    this.logMessages = this.route.snapshot.data.logMessages;
    this.notificationList = this.route.snapshot.data.notifications;
    this.languages = constants.languages;

    this.mobileQuery = media.matchMedia('(max-width: 800px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);

    this.timelineMenuVisible = !this.mobileQuery.matches;

    if (localStorage.getItem('ganttPanel')) {
      this.ganttPanelOpened = JSON.parse(localStorage.getItem('ganttPanel'));
    } else {
      this.ganttPanelOpened = true;
      localStorage.setItem('ganttPanel', this.ganttPanelOpened.toString());
    }

    // Con esto hacemos que al recargar misma url del componente, reinicie el componente entero
    route.params.subscribe(val => {
      this.ngOnInit();
    });

  }

  ngOnInit() {
    if (this.route.snapshot.data.projectInfo === 'new') {
      this.isNew = true;

      this.translate.get('PROJECT.NEW_PROJECT').subscribe((translation) => {
        this.globalService.changePageTitle([translation]);
      });
    } else {
      this.project = this.route.snapshot.data.projectInfo;
      this.globalService.changePageTitle([this.project.name]);

      this.globalService.setProjectId(this.project.id);
    }
    this.customerList = this.route.snapshot.data.customerList;
    this.pavigymCompanies = this.route.snapshot.data.pavigymCompanies;

    this.notificationsService.manageNotifications(this.notificationList, 0); // 0 is the fake formId for Info

    this.reloadProject();
  }

  ngAfterViewInit() {
    this.eventsService.doEvent({ key: 'newNotifications', value: 'true' });

    if (this.isNew) {
      this.tutorialService.modalTutorial(
        {
          tutoId: 'pj-creation',
          title: 'TUTORIAL.PROJECT_CREATION_TITLE',
          icon: 'assignment',
          content: 'TUTORIAL.PROJECT_CREATION_CONTENT'
        });
    } else {

      if (this.authService.checkRole(['cj_project_create'])) {
        this.tutorialService.modalTutorial(
          {
            tutoId: 'pj-info-welcome-pm',
            title: 'TUTORIAL.PROJECT_PM_TITLE',
            icon: 'work',
            content: 'TUTORIAL.PROJECT_PM_CONTENT'
          });
      } else {
        this.tutorialService.modalTutorial(
          {
            tutoId: 'pj-info-welcome-customer',
            title: 'TUTORIAL.PROJECT_CUSTOMER_TITLE',
            icon: 'work',
            content: 'TUTORIAL.PROJECT_CUSTOMER_CONTENT'
          });
      }
    }
  }

  ngOnDestroy(): void {
    this.globalService.setProjectId(0);
  }

  onSubmit() {
    this.loadingService.showSpinner();
    if (this.isNew) {
      this.createProject();
    } else {
      this.saveProject();
    }
  }

  public createProject() {
    this.projectFormsService.postProject(this.project).subscribe(
      result => {
        // NO MOSTRAR SI YA HAY UN MODAL DE TUTORIAL TRAS ESTE PASO, PARA NO SOLAPAR
        // this.translate.get('PROJECTS.PROJECT_INFO_CREATED').subscribe((translation) => {
        //   this.alertsService.alertTrigger({ type: 'success', msg: translation });
        // });
        this.loadingService.hideSpinner();
        this.project.id = result;
        this.router.navigate(['/project/' + result + '/info']);
        // this.reloadProject();

        // MOSTRAMOS MODAL DE TUTORIAL
        this.tutorialService.modalTutorial(
          {
            tutoId: 'pj-created',
            title: 'TUTORIAL.PROJECT_CREATED_TITLE',
            icon: 'assignment',
            content: 'TUTORIAL.PROJECT_CREATED_CONTENT'
          });
      },
      error => {
        this.loadingService.hideSpinner();
        this.translate.get('PROJECTS.PROJECT_INFO_CREATE_ERROR').subscribe((translation) => {
          this.alertsService.alertTrigger({ type: 'success', msg: translation });
        });
        console.log('Error', error);
      }
    );
  }

  public saveProject() {
    this.projectFormsService.putProject(this.project).subscribe(
      result => {
        this.translate.get('PROJECTS.PROJECT_INFO_SAVED').subscribe((translation) => {
          this.alertsService.alertTrigger({ type: 'success', msg: translation });
        });
        this.loadingService.hideSpinner();
      },
      error => {
        this.loadingService.hideSpinner();
        this.translate.get('PROJECTS.PROJECT_INFO_SAVE_ERROR').subscribe((translation) => {
          this.alertsService.alertTrigger({ type: 'success', msg: translation });
        });
        console.log('Error', error);
      }
    );
  }


  public reloadProject() {
    this.projectsService.getProject(this.project.id).subscribe(
      result => {
        Object.assign(this.project, result);
        this.eventsService.doEvent({ key: 'gantt', value: 'reload' });
        this.eventsService.doEvent({ key: 'timeline', value: { action: 'reload', project: this.project } });
      },
      error => {
        console.log('Error', error);
      }
    );
  }

  // Aux function for comparison of combobox objects
  public compare(val1, val2) {
    if (val1 !== undefined && val2 !== undefined && val1 !== null && val2 !== null) {
      return val1.id === val2.id;
    } else {
      return false;
    }
  }

  public toggleGanttPanel() {
    this.ganttPanelOpened = !this.ganttPanelOpened;
    localStorage.setItem('ganttPanel', this.ganttPanelOpened.toString());
  }

  public toggleTimelineMenu() {
    this.messagesVisible = false;
    this.timelineMenuVisible = !this.timelineMenuVisible;
  }
  public toggleMessages() {
    this.timelineMenuVisible = false;
    this.messagesVisible = !this.messagesVisible;
  }

  public canSaveProject() {
    return this.authService.isInRole(102);
  }

}
