import { Component, OnInit, Input } from '@angular/core';
import { OrderStatus } from '../../../../interfaces/order';
import { Order } from '../../../../interfaces/order';
import { constants } from '../../../../../assets/constants';
import { formatDate } from "@angular/common";

@Component({
  selector: 'app-status',
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.scss']
})
export class StatusComponent implements OnInit {

  @Input() order: Order;
  private statuses: OrderStatus[];
  private showAll: boolean;
  public currentStatus: OrderStatus;

  constructor() {
    this.statuses = constants.orderStatus;
    this.showAll = false;
  }

  ngOnInit() {
    this.order.statusId = this.order.statusId !== null ? this.order.statusId : 1;
    this.currentStatus = this.statuses.find((status) => status.id === this.order.statusId);

    // Si no existe estado definido, asignamos a 0 (unknown)
    if (this.currentStatus === undefined) {
      this.currentStatus = constants.orderStatus.find((status) => status.id === 0);
    }
  }

  public toggleShowAll() {
    this.showAll = !this.showAll;
  }

  public formatDate(date: string){
    return formatDate(date, 'd-M-yyyy', 'en-US');
    //this.order.shippingDate
  }

}
