import { Injectable } from '@angular/core';
import { ProjectFormDictionary, ProjectFormLayoutQuestion, ProjectForm } from '../interfaces/projectform';
import { AuthService } from './auth.service';
import { constants } from '../../assets/constants';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class LanguagesService {

  public currentLanguage: string;

  constructor(private translate: TranslateService) {
    this.currentLanguage = this.getLanguage();
  }

  public getValue(question: any, formData?: ProjectForm) {
    if (formData !== undefined) {
      const formDataQuestion = formData.questions.find(x => x.id === question.id);
      if (formDataQuestion) {
        return formDataQuestion.name;
      }
    }
    if (question.dictionary !== undefined) {
      const res = question.dictionary.resources.find(resource => resource.language.cultureInfo === this.currentLanguage);
      return res !== undefined ? res.value : null;
    } else {
      return question.name;
    }
  }

  public getResource(dictionary: ProjectFormDictionary) {
    return dictionary.resources.find(resource => resource.language.cultureInfo === this.currentLanguage);
  }

  public getIndex(dictionary: ProjectFormDictionary) {
    return dictionary.resources.findIndex(resource => resource.language.cultureInfo === this.currentLanguage);
  }

  public getLanguage() {
    const user = JSON.parse(localStorage.getItem('pmsession'));
    if (user) {
      return constants.languages.find(x => (x.id === user.languageId)).cultureInfo;
    } else {
      return 'en-GB';
    }
  }

  public setLanguage(languageId: number) {
    const user = JSON.parse(localStorage.getItem('pmsession'));
    user.languageId = languageId;
    localStorage.setItem('pmsession', JSON.stringify(user));
    this.currentLanguage = constants.languages.find(x => (x.id === user.languageId)).cultureInfo;
    let shortLangCode = constants.languages.find(x => (x.id === user.languageId)).cultureInfo.substr(0,2);
    this.translate.use(shortLangCode);
  }

}
