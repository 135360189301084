import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { WelcomeService } from '../../services/welcome.service';
import { WelcomeData } from '../../interfaces/welcome';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginData } from '../../interfaces/logindata';
import { constants } from '../../../assets/constants';
import { AuthService } from '../../services/auth.service';
import { AlertsService } from '../../services/alerts.service';
import { EventsService } from '../../services/events.service';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent implements OnInit {

  password: string;
  repeatPassword: string;
  passwordMatch: boolean;
  hash: string;
  userEmail: string;

  constructor(private translate: TranslateService,
    private welcomeService: WelcomeService,
    private route: ActivatedRoute,
    private authService: AuthService,
    private alertsService: AlertsService,
    private eventsService: EventsService,
    private router: Router) {
    this.passwordMatch = true;
    this.userEmail = '';

    if (this.authService.isLogged()) {
      this.router.navigate(['/']);
    }
  }

  ngOnInit() {
    this.hash = this.route.snapshot.paramMap.get('hash');

    this.welcomeService.getWelcomeUser(this.hash).subscribe(
      result => {
        if (result) {
          this.userEmail = result.name;
        }
      },
      error => {
        this.userEmail = null;
      }
    );
  }

  onSubmit() {
    if (this.password === this.repeatPassword && this.password !== '' && this.repeatPassword !== '') {
      this.passwordMatch = true;
      const welcomeData: WelcomeData = {
        hash: this.hash,
        password: this.password
      };
      this.welcomeService.putPassword(welcomeData).subscribe(
        result => {
          this.login(this.userEmail, this.password)
        }
      );
    } else {
      this.passwordMatch = false;
    }
  }

  login(username: string, password: string) {
    const data: LoginData = {
      email: username,
      password: password,
      appId: constants.appId
    };
    this.authService.checkLogin(data)
      .subscribe(resp => {
        this.authService.saveSession(resp);

        this.translate.get('LOGIN.LOGIN_PASSWORD_SET').subscribe((translation) => {
          this.alertsService.alertTrigger({ msg: translation, button: 'Ok', time: 6000 });
          this.router.navigate(['']);

          this.eventsService.doEvent({ key: 'login', value: 'ok' });
        });
      }, error => {
        this.translate.get('LOGIN.INVALID_CREDENTIALS').subscribe((translation) => {
          this.alertsService.alertTrigger({ msg: translation, button: 'Ok' });
          this.password = username = '';
        });
      });
  }
}
