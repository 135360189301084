import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TutorialModalComponent } from '../components/common/tutorial/tutorial.component';

@Injectable({
  providedIn: 'root'
})
export class TutorialService {

  public tutorialOverride: string[];
  public dontShowAgain: boolean;
  public showTutorial: boolean;

  constructor(private dialog: MatDialog) { }

  public modalTutorial(data: any) {
    this.dontShowAgain = false;
    this.showTutorial = true;
    this.tutorialOverride = JSON.parse(localStorage.getItem('tutorialOverride'));
    if (this.tutorialOverride === null) {
      this.tutorialOverride = [];
      //this.openTutorial(data);
    } else {
      if (!this.tutorialOverride.includes(data.tutoId)) {
        //this.openTutorial(data);
      }
    }

    //////////////////////////////////////
    // Eliminar para no forzar tutorial override    
    this.tutorialOverride = JSON.parse(localStorage.getItem('tutorialOverride'));
    if (this.tutorialOverride === null) {
      this.tutorialOverride = [];
    }
    this.tutorialOverride.push(data.tutoId);
    localStorage.setItem('tutorialOverride', JSON.stringify(this.tutorialOverride));
    //////////////////////////////////////
  }

  private openTutorial(data: any) {
    this.dialog.open(TutorialModalComponent, {
      data: data,

      panelClass: 'tutorial-panel'
    });
  }
}
