import { Component, OnInit, TemplateRef, Inject, SecurityContext } from '@angular/core';
import { constants } from '../../../../assets/constants';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';

import { TasksService } from '../../../services/tasks.service';
import { Task } from '../../../interfaces/task';

import { AngularEditorConfig } from '@kolkov/angular-editor';
import { EventsService } from '../../../services/events.service';

@Component({
    selector: 'app-modal-task-detail',
    templateUrl: 'modal-task-detail.html',
})
export class ModalTaskDetailComponent implements OnInit {

    public task: Task;
    readonly separatorKeysCodes: number[] = [ENTER, COMMA];
    private remindDelay: number = 0;
    private taskRemindOptions;
    private taskTrackOptions;

    editorConfig: AngularEditorConfig = {
        editable: true,
        spellcheck: true,
        height: 'auto',
        minHeight: '0',
        maxHeight: 'auto',
        width: '100%',
        minWidth: '0',
        translate: 'yes',
        enableToolbar: true,
        showToolbar: true,
        placeholder: 'Enter text here...',
        defaultParagraphSeparator: '',
        defaultFontName: '',
        defaultFontSize: '',
        fonts: [
            { class: 'arial', name: 'Arial' },
            { class: 'times-new-roman', name: 'Times New Roman' },
            { class: 'calibri', name: 'Calibri' },
            { class: 'comic-sans-ms', name: 'Comic Sans MS' }
        ],
        customClasses: [
            {
                name: 'quote',
                class: 'quote',
            },
            {
                name: 'redText',
                class: 'redText'
            },
            {
                name: 'titleText',
                class: 'titleText',
                tag: 'h1',
            },
        ],
        uploadUrl: 'v1/image',
        sanitize: true,
        toolbarPosition: 'top',
        toolbarHiddenButtons: [
            ['insertImage', 'insertVideo']
        ]
    };

    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
        private tasksService: TasksService,
        public dialogRef: MatDialogRef<ModalTaskDetailComponent>,
        private events: EventsService) {
        this.taskRemindOptions = constants.taskRemindOptions;
        this.taskTrackOptions = constants.taskTrackOptions;
    }

    ngOnInit(): void {
        this.getTask();
    }

    private getTask() {
        this.tasksService.getTask(this.data.id).subscribe(
            data => {
                this.task = data;
                this.task.action = 2;
                this.calculateRemindDate();
            },
            error => {
                console.log('Error:' + error);
            }
        );
    }

    private add(event: MatChipInputEvent, list: string[]): void {
        const input = event.input;
        const value = event.value;

        // Add our fruit
        if ((value || '').trim()) {
            list.push(value.trim());
        }

        // Reset the input value
        if (input) {
            input.value = '';
        }
    }

    private remove(email: string, list: string[]): void {
        const index = list.indexOf(email);

        if (index >= 0) {
            list.splice(index, 1);
        }
    }

    private calculateRemindDate(delay?: any) {
        if (this.task.action === 1) {
            this.remindDelay = 1;
        }
        if (this.task.action === 2) {
            this.remindDelay = 0;
        }

        // delay will provide the time delay in hours
        const initialDate = new Date();
        if (delay) {
            initialDate.setHours(initialDate.getHours() + delay);
        } else {
            initialDate.setHours(initialDate.getHours());
        }
        const isoDate = new Date(initialDate.getTime() - (initialDate.getTimezoneOffset() * 60000)).toISOString().slice(0, -1);
        this.task.remind = isoDate;
    }

    private setTask() {
        this.tasksService.putTask(this.task).subscribe(
            data => {
                this.events.doEvent({key: 'tasklist', value: 'reload'});
            },
            error => {
                console.log('Error:' + error);
            }
        );
    }

    // Función duplicada en tasks.component.ts  - Sacar a servicio en futuro
    private unTrackTask(task: Task) {
        task.action = 3;
        task.remind = null;
        this.tasksService.putTask(task).subscribe(
          result => {        
            this.events.doEvent({key: 'tasklist', value: 'reload'});
          },
          error => {
            console.log('Error');
          }
        );
      }
}
