import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/of';
import { FormsService } from '../services/forms.service';
import { FormManagerService } from '../services/formmanager.service';

@Injectable()
export class FormManagerEditResolver implements Resolve<Observable<string>> {

    constructor(private formManagerService: FormManagerService) { }

    resolve(route: ActivatedRouteSnapshot) {
        if (route.paramMap.get('id') !== 'new') {
            return this.formManagerService.getForm(route.paramMap.get('id')).catch(() => {
                return Observable.of('error');
            });
        } else {
            return Observable.of('new');
        }
    }
}
