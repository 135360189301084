import { Component, OnInit, Input } from '@angular/core';
import { Order } from '../../../interfaces/order';

@Component({
  selector: 'app-order-detail',
  templateUrl: './order-detail.component.html',
  styleUrls: ['./order-detail.component.scss']
})
export class OrderDetailComponent implements OnInit {

  @Input() order: Order;
  @Input() isModal: boolean;

  public linesPanelOpened: boolean;

  constructor() {
    this.linesPanelOpened = false;
  }

  ngOnInit() {
  }

  public toggleLinesPanel() {
    this.linesPanelOpened = !this.linesPanelOpened;
  }

}
