import { Component, OnInit, Input } from '@angular/core';

import { AuthService } from '../../../services/auth.service';
import { constants } from '../../../../assets/constants';

import { Project, GanttTableElement } from '../../../interfaces/project';
import { ActivatedRoute } from '@angular/router';
import { UserNotification } from '../../../interfaces/notification';
import { ProjectStatus } from '../../../interfaces/projectStatus';
import { ProjectFormAvailable } from '../../../interfaces/projectform';
import { EventsService } from '../../../services/events.service';
import { FormsService } from '../../../services/forms.service';
@Component({
  selector: 'app-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss']
})
export class TimelineComponent implements OnInit {

  @Input() notifications: UserNotification[];
  @Input() formsAvailable: ProjectFormAvailable[];
  @Input() project: Project;

  public timeline;
  public isNew;

  ganttTable: GanttTableElement[];

  // notifications: UserNotification[];

  constructor(private authService: AuthService,
    private formsService: FormsService,
    private events: EventsService,
    private route: ActivatedRoute) {
    this.timeline = [
      {
        number: '1',
        title: 'Project Registration',
        active: true,
        icon: 'assignment',
        requirements: [1, 20],
        successEventId: constants.timelineEvents.KICKOFF_MEETING_PROPOSAL,
        levels: [
          {
            title: 'Main info',
            formId: 0,
            url: '/project/[projectId]/info',
            roles: ['cj_tl_project_info_view', 'cj_tl_project_info_modify'],
            active: true
          },
          {
            title: 'Dates',
            formId: 23,
            url: '/project/[projectId]/form/[formId]',
            roles: ['cj_tl_dates_view', 'cj_tl_dates_modify'],
            active: true
          }
        ]
      },
      {
        number: '2',
        title: 'KickOff Meeting',
        active: true,
        icon: 'speaker_notes',
        requirements: [1, 7, 8, 20],
        successEventId: constants.timelineEvents.ROADMAP,
        levels: [
          {
            title: 'Meeting date',
            formId: 3,
            url: '/project/[projectId]/form/[formId]',
            roles: ['cj_tl_kom_date_view', 'cj_tl_kom_date_modify'],
            active: true
          },
          {
            title: 'Form filling',
            formId: 11,
            url: '/project/[projectId]/form/[formId]',
            roles: ['cj_tl_kom_form_view'],
            active: true,
            hasReport: true,
          },
          {
            title: 'Gallery',
            formId: 13,
            url: '/project/[projectId]/form/[formId]',
            roles: ['cj_tl_kom_gallery_view'],
            active: true
          },
          // {
          //   title: 'Report',
          //   formId: 0,
          //   url: '/project/[projectId]/report/[formId]',
          //   roles: ['cj_tl_kom_report'],
          //   active: true
          // }
        ]
      },
      {
        number: '3',
        title: 'Roadmap',
        active: true,
        icon: 'map',
        // requirements: [23, 24],
        requirements: [1, 20],
        successEventId: constants.timelineEvents.ROADMAP,
        levels: [
          {
            title: 'Date proposal',
            formId: 26,
            url: '/project/[projectId]/form/[formId]',
            roles: ['cj_tl_roadmap_date_view', 'cj_tl_roadmap_date_modify'],
            active: true
          }
        ]
      },
      {
        number: '4',
        title: 'Delivery',
        active: true,
        icon: 'local_shipping',
        requirements: [20],
        successEventId: constants.timelineEvents.FIRST_SITE_VISIT_PROPOSAL,
        levels: [
          {
            title: 'Delivery date',
            formId: 28,
            url: '/project/[projectId]/form/[formId]',
            roles: ['cj_tl_delivery_date_view', 'cj_tl_delivery_date_modify'],
            active: true
          },
          {
            title: 'Notes',
            formId: 30,
            url: '/project/[projectId]/form/[formId]',
            roles: ['cj_tl_delivery_form_view'],
            active: true,
            hasReport: true,
          },
          {
            title: 'Dispatch note',
            formId: 29,
            url: '/project/[projectId]/form/[formId]',
            roles: ['cj_tl_delivery_gallery_view'],
            active: true
          },
          // {
          //   title: 'Report',
          //   formId: 0,
          //   url: '/project/[projectId]/report/[formId]',
          //   roles: ['cj_tl_first_report'],
          //   active: true
          // }
        ]
      },
      {
        number: '5',
        title: '1st Site Visit',
        active: true,
        icon: 'flight_takeoff',
        requirements: [11, 12, 20],
        successEventId: constants.timelineEvents.INSTALLATION,
        levels: [
          {
            title: 'Visit date',
            formId: 12,
            url: '/project/[projectId]/form/[formId]',
            roles: ['cj_tl_first_date_view', 'cj_tl_first_date_modify'],
            active: true
          },
          {
            title: 'Form filling',
            formId: 14,
            url: '/project/[projectId]/form/[formId]',
            roles: ['cj_tl_first_form_view'],
            active: true,
            hasReport: true,
          },
          {
            title: 'Gallery',
            formId: 15,
            url: '/project/[projectId]/form/[formId]',
            roles: ['cj_tl_first_gallery_view'],
            active: true
          },
          // {
          //   title: 'Report',
          //   formId: 0,
          //   url: '/project/[projectId]/report/[formId]',
          //   roles: ['cj_tl_first_report'],
          //   active: true
          // }
        ]
      },
      {
        number: '6',
        title: 'Construction works',
        active: true,
        icon: 'format_paint',
        // requirements: [13, 14],
        requirements: [1, 20],
        successEventId: constants.timelineEvents.INSTALLATION,
        levels: [
          {
            title: 'Terms & Conditions',
            formId: 16,
            url: '/project/[projectId]/form/[formId]',
            roles: ['cj_tl_construction_works_view'],
            active: true
          },
          {
            title: 'Gallery',
            formId: 17,
            url: '/project/[projectId]/form/[formId]',
            roles: ['cj_tl_construction_works_gallery_view'],
            active: true
          }
        ]
      },
      {
        number: '7',
        title: 'Installation',
        active: true,
        icon: 'build',
        requirements: [25, 26],
        successEventId: constants.timelineEvents.TRAINING,
        levels: [
          {
            title: 'Date proposal',
            formId: 5,
            url: '/project/[projectId]/form/[formId]',
            roles: ['cj_tl_install_date_view', 'cj_tl_install_date_modify'],
            active: true
          },
          {
            title: 'Installation Form filling',
            formId: 27,
            url: '/project/[projectId]/form/[formId]',
            roles: ['cj_tl_install_view', 'cj_tl_install_modify'],
            active: true
          },
          {
            title: 'PDI Gallery',
            formId: 18,
            url: '/project/[projectId]/form/[formId]',
            roles: ['cj_tl_install_pdi_view'],
            active: true
          },
          {
            title: 'Installation Pictures',
            formId: 19,
            url: '/project/[projectId]/form/[formId]',
            roles: ['cj_tl_install_pic_view'],
            active: true
          }
        ]
      },
      {
        number: '8',
        title: 'Training',
        active: true,
        icon: 'fitness_center',
        requirements: [20],
        successEventId: constants.timelineEvents.OPENING,
        levels: [
          {
            title: 'Date proposal',
            formId: 25,
            url: '/project/[projectId]/form/[formId]',
            roles: ['cj_tl_training_date_view', 'cj_tl_training_date_modify'],
            active: true
          }
        ]
      },
      {
        number: '9',
        title: 'Completion',
        active: true,
        icon: 'done_all',
        requirements: [15, 16, 17, 18],
        successEventId: constants.timelineEvents.OPENING,
        levels: [
          {
            title: 'PCC Form filling',
            formId: 20,
            url: '/project/[projectId]/form/[formId]',
            roles: ['cj_tl_installation_pcc_view'],
            active: true,
            hasReport: true,
          },
          // {
          //   title: 'PCC Report',
          //   formId: 0,
          //   url: '/project/[projectId]/report/[formId]',
          //   roles: ['cj_tl_installation_pcc_report'],
          //   active: true
          // },
          {
            title: 'CC Form filling',
            formId: 21,
            url: '/project/[projectId]/form/[formId]',
            roles: ['cj_tl_installation_cc_view'],
            active: true,
            hasReport: true,
          },
          // {
          //   title: 'CC Report',
          //   formId: 0,
          //   url: '/project/[projectId]/report/[formId]',
          //   roles: ['cj_tl_installation_cc_report'],
          //   active: true
          // },
          {
            title: 'Gallery',
            formId: 22,
            url: '/project/[projectId]/form/[formId]',
            roles: ['cj_tl_installation_completion_gallery_view'],
            active: true
          }
        ]
      },
      {
        number: '10',
        title: 'Opening',
        active: true,
        icon: 'store',
        requirements: [20],
        successEventId: constants.timelineEvents.OPENING,
        levels: [
          {
            title: 'Opening date',
            formId: 4,
            url: '/project/[projectId]/form/[formId]',
            roles: ['cj_tl_opening_date_view', 'cj_tl_opening_date_modify'],
            active: true
          },
          {
            title: 'Gallery',
            formId: 24,
            url: '/project/[projectId]/form/[formId]',
            roles: ['cj_tl_opening_date_gallery_view'],
            active: true
          }
        ]
      },
    ];

    this.isNew = false;

  }

  ngOnInit() {

    // this.notifications = this.notificationList;

    if (this.project === undefined || this.project.id === 0) {
      this.isNew = true;
      // this.project = {
      //   id: 0,
      //   annotations: '',
      //   name: '',
      //   order: '',
      //   company: '',
      //   location: '',
      //   address: '',
      //   city: '',
      //   country: '',
      //   contactEmail: '',
      //   contactName: '',
      //   contactSurnames: '',
      //   contactTelephone: '',
      //   creator: '',
      //   customer: null,
      //   status: null,
      //   language: null,
      //   productType: null
      // };
    }
    if (!this.formsAvailable) {
      this.formsAvailable = this.route.snapshot.data.formsAvailable;
    } else {
      this.formsService.getFormsAvailable(this.project.id).subscribe(
        result => {
          this.formsAvailable = result;
        },
        error => {
          console.log('Error', error);
        }
      );
    }

    this.events.event$.subscribe((params: any) => {
      if (params.key === 'timeline') {
        if (params.value.action === 'reload') {
          this.formsService.getFormsAvailable(params.value.project.id).subscribe(
            result => {
              this.formsAvailable = result;
              this.isNew = false;
            },
            error => {
              console.log('Error', error);
            }
          );

        }
      }
    });

    this.ganttTable = this.route.snapshot.data.projectInfo.ganttTable;
  }

  public hasNotifications(formId: number) {
    if (this.notifications) {
      const not: UserNotification[] = this.notifications.filter(x => x.notification.formId === formId && x.project.id === this.project.id && x.isViewed !== true);
      return not.length > 0;
    } else {
      return false;
    }
  }

  public getNotifications(formId: number) {
    if (this.notifications) {
      const not: UserNotification[] = this.notifications.filter(x => x.notification.formId === formId && x.project.id === this.project.id);
      return not;
    } else {
      return [];
    }
  }

  public getUrl(projectId, level) {
    if (projectId !== 0) {
      return level.url.replace('[projectId]', projectId).replace('[formId]', level.formId);
    } else {
      return level.url.replace('[projectId]', 'new').replace('[formId]', level.formId);
    }
  }

  public isReportReady(formId: number) {
    if (this.project.status) {
      const status = this.project.status.find(x => (x.formId === formId && x.typeId === 2));
      if (status !== undefined) {
        return status.achieved;
      } else {
        return false;
      }
    }
  }

  public canAccess(level: any) {
    if (this.formsAvailable !== undefined) {
      const foundLevel = this.formsAvailable.find(x => x.idForm === level.formId);
      const ret = (foundLevel !== undefined && foundLevel.available) || level.formId === 0;
      return ret;
    } else {
      return false;
    }
  }

  public checkDateSuccess(eventId: number) {
    if (this.ganttTable !== null && this.ganttTable !== undefined) {
      const startDate = this.ganttTable.find(x => x.id === eventId).actualStartDate;
      const endDate = this.ganttTable.find(x => x.id === eventId).actualEndDate;

      const currentDate = new Date();

      if (currentDate >= new Date(startDate) && startDate) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  public isAchieved(requirements: number[]) {
    let achieved = true;
    if (this.project.status !== null) {
      requirements.forEach(req => {
        if (this.project.status.find(x => x.id === req).achieved === false) {
          achieved = false;
        }
      });
    } else {
      achieved = false;
    }
    return achieved;
  }

  public getIconTitle(step: any) {
    let dataAchieved;
    if (this.isAchieved(step.requirements)) {
      dataAchieved = 'Well done. All needed info and actions for step "' + step.title + '" are done';
    } else {
      dataAchieved = 'Info or actions needed for step "' + step.title + '". Please take a look at it';
    }
    return dataAchieved;
  }

  public getArrowsTitle(step: any) {
    let dateAchieved;
    if (this.checkDateSuccess(step.successEventId)) {
      dateAchieved = 'Project progress state has already passed through this date';
    } else {
      dateAchieved = 'Project progress state has not yet reached this date';
    }
    return dateAchieved;
  }
}

