import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { HttpClient } from '@angular/common/http';
import { constants } from '../../assets/constants';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  private urlConfigGet = constants.urlAPI + constants.endpoints.config.get;
  private urlConfigPost = constants.urlAPI + constants.endpoints.config.post;

  public userConfig: any;

  constructor(public http: HttpClient) {    
    this.userConfig = {
      uiOptions: ''
    };
    this.retrieveConfig();
  }

  public retrieveConfig(){
    this.getConfig().subscribe(
      result => {         
        this.userConfig = JSON.parse(result.uiOptions);
        if(this.userConfig === undefined || this.userConfig === null){
          this.userConfig = {};
        } 
      },
      error => { console.log('Error: loadConfig'); }
    );
  }

  public uploadConfig(){
    this.postConfig().subscribe(
      result => { 
        //this.userConfig = JSON.parse(result); 
      },
      error => { console.log('Error: loadConfig'); }
    );
  }

  public getConfig(): Observable<any> {    
      return this.http.get(this.urlConfigGet);
  }

  public postConfig(): Observable<any> {
    const data = this.userConfig;
    return this.http.post(this.urlConfigPost, data);
  }
  

  public getItem(param: string){    
    return this.userConfig !== undefined && this.userConfig !== null && this.userConfig[param] !== null ? this.userConfig[param] : null;
  }

  public setItem(param: string, value: string){
    // if(this.userConfig.uiOptions === undefined){
    //   this.userConfig.uiOptions = {};
    // }
    let outputJson = {};
    outputJson[param] = value;
    this.userConfig['uiOptions'] = JSON.stringify(outputJson);
    this.uploadConfig();
  }
}
