import { Component, OnInit, Input } from '@angular/core';
import { LogMessage } from '../../../interfaces/logmessage';
import { Project } from '../../../interfaces/project';
import { LogService } from '../../../services/log.service';

@Component({
  selector: 'app-mainlog',
  templateUrl: './mainlog.component.html',
  styleUrls: ['./mainlog.component.scss']
})
export class MainlogComponent implements OnInit {

  @Input() logMessages: LogMessage[];
  @Input() formId?: Number;
  // @Input() project: Project;

  filteredMessages: LogMessage[];

  constructor() { }

  ngOnInit() {
    if (this.formId !== undefined && this.logMessages !== undefined) {
      this.filteredMessages = this.logMessages.filter(msg => msg.log.formId === this.formId);
    } else {
      this.filteredMessages = this.logMessages;
    }
  }

}
