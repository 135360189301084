import { Component, OnInit } from '@angular/core';
import { Setting } from '../../interfaces/setting';
import { SettingsGroup } from '../../interfaces/setting';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

  public settings: SettingsGroup[];

  constructor() {
    this.settings = [
      {
        key: 'GENERAL',
        order: 1,
        settings: [
          {
            key: 'EMAIL_NOTIFICATIONS',
            value: true,
            type: 1 // 1-toggle
          },
          {
            key: 'PLATFORM_NOTIFICATIONS',
            value: true,
            type: 1
          }
        ]
      },
      {
        key: 'PAYMENTS',
        order: 1,
        settings: [
          {
            key: 'NOTIFY_EMAIL_PARTIAL_PAYMENT_USER',
            value: true,
            type: 1
          },
          {
            key: 'NOTIFY_EMAIL_TOTAL_PAYMENT_USER',
            value: true,
            type: 1
          },
          {
            key: 'NOTIFY_EMAIL_PARTIAL_PAYMENT_SALESTAKER',
            value: true,
            type: 1
          },
          {
            key: 'NOTIFY_EMAIL_TOTAL_PAYMENT_SALESTAKER',
            value: true,
            type: 1
          }
        ]
      }
    ];
  }

  ngOnInit() {
  }

  public onSubmit(){
    // Do whatever
  }
}
