import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GlobalService } from '../../services/global.service';
import { AuthService } from '../../services/auth.service';
import { constants } from '../../../assets/constants';
import { TutorialService } from '../../services/tutorial.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  // showLinks: boolean;
  redirectTo: string;

  constructor(private translate: TranslateService,
    private globalService: GlobalService,
    public authService: AuthService,
    private tutorialService: TutorialService) { }

  ngOnInit() {

    if (this.authService.checkRole(['cj_tl_project_info_view'])
      && !this.authService.checkRole(['cj_sales_order_info_view'])) {
      this.redirectTo = '/projects';
    }
    if (!this.authService.checkRole(['cj_tl_project_info_view'])
      && this.authService.checkRole(['cj_sales_order_info_view'])) {
      this.redirectTo = '/orders';
    }

    this.translate.get('HOME.TITLE').subscribe((translation) => {
      this.globalService.changePageTitle([translation]);
    });

    this.tutorialService.modalTutorial(
      {
        tutoId: 'home-welcome',
        title: 'TUTORIAL.HOME_WELCOME_TITLE',
        image: 'prama-customer-journey.png',
        redirectTo: this.redirectTo,
        content: 'TUTORIAL.HOME_WELCOME_CONTENT'
      });
  }

}
