import { Component, OnInit, TemplateRef, Inject, SecurityContext } from '@angular/core';
import { constants } from '../../../../assets/constants';
import { ActivatedRoute, Router } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { GlobalService } from '../../../services/global.service';
import { TranslateService } from '@ngx-translate/core';
import { AlertsService } from '../../../services/alerts.service';
import { ProjectFile } from '../../../interfaces/projectfile';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { FilesService } from '../../../services/files.service';
import { saveAs } from 'file-saver';

@Component({
    selector: 'app-modal-download-file',
    templateUrl: 'modal-download-file.html',
})
export class ModalDownloadFileComponent implements OnInit {

    file: ProjectFile;
    fileUrl;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<ModalDownloadFileComponent>,
        private globalService: GlobalService,
        private router: Router,
        private translate: TranslateService,
        private alertsService: AlertsService,
        private filesService: FilesService,
        private sanitizer: DomSanitizer) {

        this.file = data.file;
    }

    ngOnInit(): void {
        this.getFileDownload();
    }

    private getFileDownload() {
        this.filesService.getFileDownload(this.file.uniqueId).subscribe(
            data => {
                saveAs(data, this.file.fileName);
                this.dialogRef.close();
            },
            error => {
                console.log('Error:' + error);
            }
        );
    }
}
