import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/of';
import { NotificationsService } from '../services/notifications.service';

@Injectable()
export class NotificationsResolver implements Resolve<Observable<string>> {

    constructor(private notificationsService: NotificationsService) { }

    resolve(route: ActivatedRouteSnapshot) {
        if (route.paramMap.get('id') !== 'new') {
            return this.notificationsService.getNotifications().catch(() => {
                return Observable.of('error');
            });
        } else {
            return Observable.of([]);
        }
    }
}
