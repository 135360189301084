import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Role } from '../interfaces/role';
import { Profile } from '../interfaces/profile';
import { Status } from '../interfaces/status';

import { ProfilesService } from './profiles.service';
import { RolesService } from './roles.service';
import { HttpClient } from '@angular/common/http';
import { UsersService } from './users.service';
import { Subject } from 'rxjs/Subject';
import { EventsService } from './events.service';
import { constants } from '../../assets/constants';
import { SessionToken } from '../interfaces/sessiontoken';
import { InputType } from '../interfaces/inputType';
import { FormsService } from './forms.service';
import { ProjectProductType } from '../interfaces/project';
import { ProjectsService } from './projects.service';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { LoadingService } from './loading.service';

@Injectable()
export class GlobalService {
  profileList: Profile[];
  roleList: Role[];
  userStatusList: Status[];
  productTypeList: ProjectProductType[];

  public hrTypeList = [{ id: 0, name: 'Ant+' }, { id: 2, name: 'WASP' }, { id: 1, name: 'MyZone' }];

  // Observable string sources
  private titleEventSource = new Subject<string>();
  // Observable string streams
  pageTitleChangeEvent$ = this.titleEventSource.asObservable();

  public currentLanguage: number;
  public currentProjectId: number;

  public lastNotificationDate: string;
  public lastChatDate: string;
  public isPartnerAvailable: boolean;

  public isNewDataInterval;

  constructor(public http: HttpClient,
    private rolesService: RolesService,
    private profilesService: ProfilesService,
    private usersService: UsersService,
    private projectsService: ProjectsService,
    private eventsService: EventsService,
    private router: Router,
    private loading: LoadingService) {

    this.eventsService.event$.subscribe((params: any) => {
      if (params.key === 'login' && params.value === 'ok') {
        this.startDataRefresher();
        this.loadGlobals();
      }
    });

    this.startDataRefresher();
    this.loadGlobals();

    this.currentLanguage = 1;
    this.currentProjectId = 0;

    this.lastNotificationDate = null;
    this.lastChatDate = null;
    this.isPartnerAvailable = false;

    this.eventsService.event$.subscribe((params: any) => {
      if (params.key === 'logout' && params.value === 'ok') {
        this.loadGlobals();
        clearInterval(this.isNewDataInterval);
      }
    });

    // Mostramos / ocultamos Spinner de carga entre navegaciones
    this.router.events.subscribe((val) => {
      if (val instanceof NavigationStart) {
        this.loading.showSpinner();
      }

      if (val instanceof NavigationEnd) {
        this.loading.hideSpinner();
      }
    });
  }

  private loadGlobals() {
    this.loadRoles();
    // this.loadProfiles();
    this.loadProductTypes();
    // this.loadUserStatus();
  }

  public getGlobals(): Observable<any> {
    const globalData = {
      // profileList: this.profileList,
      roleList: this.roleList,
      userStatusList: this.userStatusList,
    };

    return Observable.of(globalData);
  }

  private loadRoles() {
    this.rolesService.getRoles().subscribe(
      result => { this.roleList = result; },
      error => { console.log('Error: loadRoles'); }
    );
  }

  public getRoles() { return this.roleList; }


  public getRoleIdByName(name: string) {
    return this.roleList.find(x => x.name === name).id;
  }

  private loadProfiles() {
    this.profilesService.getProfiles().subscribe(
      result => { this.profileList = result; },
      error => { console.log('Error: loadProfiles'); }
    );
  }
  public getProfiles() { return this.profileList; }

  private loadProductTypes() {
    this.projectsService.getProductTypes().subscribe(
      result => { this.productTypeList = result; },
      error => { console.log('Error: loadProductTypes'); }
    );
  }

  public getProductTypes() { return this.productTypeList; }

  private loadUserStatus() {
    this.usersService.getUserStatus().subscribe(
      result => { this.userStatusList = result; },
      error => { console.log('Error: loadUserStatus'); }
    );
  }
  public getUserStatusList() { return this.userStatusList; }

  public removeByKey(array, params) {
    array.some(function (item, index) {
      return (array[index][params.key] === params.value) ? !!(array.splice(index, 1)) : false;
    });
    return array;
  }

  public removeByKeys(array, params) {
    array.some(function (item, index) {
      return (array[index][params.key] === params.value && array[index][params.key2] === params.value2) ? !!(array.splice(index, 1)) : false;
    });
    return array;
  }

  public changePageTitle(params) {
    this.titleEventSource.next(params);
  }

  public countryList() {
    return ['Afghanistan', 'Albania', 'Algeria', 'Andorra', 'Angola', 'Antigua & Deps', 'Argentina',
      'Armenia', 'Australia', 'Austria', 'Azerbaijan', 'Bahamas', 'Bahrain', 'Bangladesh', 'Barbados',
      'Belarus', 'Belgium', 'Belize', 'Benin', 'Bhutan', 'Bolivia', 'Bosnia Herzegovina', 'Botswana',
      'Brazil', 'Brunei', 'Bulgaria', 'Burkina', 'Burundi', 'Cambodia', 'Cameroon', 'Canada', 'Cape Verde',
      'Central African Rep', 'Chad', 'Chile', 'China', 'Colombia', 'Comoros', 'Congo', 'Congo {Democratic Rep}',
      'Costa Rica', 'Croatia', 'Cuba', 'Cyprus', 'Czech Republic', 'Denmark', 'Djibouti', 'Dominica',
      'Dominican Republic', 'East Timor', 'Ecuador', 'Egypt', 'El Salvador', 'Equatorial Guinea',
      'Eritrea', 'Estonia', 'Ethiopia', 'Fiji', 'Finland', 'France', 'Gabon', 'Gambia', 'Georgia',
      'Germany', 'Ghana', 'Greece', 'Grenada', 'Guatemala', 'Guinea', 'Guinea-Bissau', 'Guyana',
      'Haiti', 'Honduras', 'Hungary', 'Iceland', 'India', 'Indonesia', 'Iran', 'Iraq', 'Ireland {Republic}',
      'Israel', 'Italy', 'Ivory Coast', 'Jamaica', 'Japan', 'Jordan', 'Kazakhstan', 'Kenya', 'Kiribati',
      'Korea North', 'Korea South', 'Kosovo', 'Kuwait', 'Kyrgyzstan', 'Laos', 'Latvia', 'Lebanon', 'Lesotho',
      'Liberia', 'Libya', 'Liechtenstein', 'Lithuania', 'Luxembourg', 'Macedonia', 'Madagascar', 'Malawi',
      'Malaysia', 'Maldives', 'Mali', 'Malta', 'Marshall Islands', 'Mauritania', 'Mauritius', 'Mexico',
      'Micronesia', 'Moldova', 'Monaco', 'Mongolia', 'Montenegro', 'Morocco', 'Mozambique', 'Myanmar, {Burma}',
      'Namibia', 'Nauru', 'Nepal', 'Netherlands', 'New Zealand', 'Nicaragua', 'Niger', 'Nigeria', 'Norway',
      'Oman', 'Pakistan', 'Palau', 'Panama', 'Papua New Guinea', 'Paraguay', 'Peru', 'Philippines', 'Poland',
      'Portugal', 'Qatar', 'Romania', 'Russian Federation', 'Rwanda', 'St Kitts & Nevis', 'St Lucia',
      'Saint Vincent & the Grenadines', 'Samoa', 'San Marino', 'Sao Tome & Principe', 'Saudi Arabia', 'Senegal',
      'Serbia', 'Seychelles', 'Sierra Leone', 'Singapore', 'Slovakia', 'Slovenia', 'Solomon Islands', 'Somalia',
      'South Africa', 'South Sudan', 'Spain', 'Sri Lanka', 'Sudan', 'Suriname', 'Swaziland', 'Sweden', 'Switzerland',
      'Syria', 'Taiwan', 'Tajikistan', 'Tanzania', 'Thailand', 'Togo', 'Tonga', 'Trinidad & Tobago', 'Tunisia',
      'Turkey', 'Turkmenistan', 'Tuvalu', 'Uganda', 'Ukraine', 'United Arab Emirates', 'United Kingdom',
      'United States', 'Uruguay', 'Uzbekistan', 'Vanuatu', 'Vatican City', 'Venezuela', 'Vietnam', 'Yemen',
      'Zambia', 'Zimbabwe'];
  }

  public postFile(data: File): Observable<string> {
    return Observable.create(observer => {
      const url = constants.urlAPI + constants.endpoints.media.post;
      const formData: FormData = new FormData();
      const xhr: XMLHttpRequest = new XMLHttpRequest();
      const token: SessionToken = JSON.parse(localStorage.getItem('pmsession'));
      formData.append('files', data, data.name);

      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            observer.next(JSON.parse(xhr.response).resultFiles[0]);
            observer.complete();
          } else {
            observer.error(xhr.response);
          }
        }
      };
      xhr.open('POST', url, true);
      xhr.setRequestHeader('Authorization', 'Bearer ' + token.token);
      xhr.send(formData);
    });
  }

  public fixUTC(date: Date) {
    date.setHours(date.getHours() + 2);
    return date;
  }

  public startDataRefresher() {
    this.isNewDataInterval = setInterval(() => {
      this.isNewData();
    }, 3000);
  }

  public isNewData() {
    this.getIsNewData().subscribe(
      result => {
        if (this.lastNotificationDate === undefined || this.lastNotificationDate === null) {
          this.lastNotificationDate = result.notificationDate;
        }
        if (this.lastChatDate === undefined || this.lastChatDate === null) {
          this.lastChatDate = result.chatDate;
        }

        if (new Date(result.notificationDate) > new Date(this.lastNotificationDate)) {
          this.lastNotificationDate = result.notificationDate;
          this.eventsService.doEvent({ key: 'newNotifications', value: 'true' });
        }

        if (new Date(result.chatDate) > new Date(this.lastChatDate)) {
          this.lastChatDate = result.chatDate;
          this.eventsService.doEvent({ key: 'newChat', value: 'true' });
        }

        this.isPartnerAvailable = result.isPartnerAvailable;
      },
      error => {
        console.log('Error', error);
      }
    );
  }

  public getIsNewData(): Observable<any> {
    let urlCronDataVerify = constants.urlAPI + constants.endpoints.cronDataVerify.get;
    if (this.currentProjectId === 0 || this.currentProjectId === undefined) {
      urlCronDataVerify = urlCronDataVerify.replace('/:id', '');
    } else {
      urlCronDataVerify = urlCronDataVerify.replace(':id', this.currentProjectId.toString());
    }
    return this.http.get(urlCronDataVerify);
  }

  public getIsPartnerAvailable() {
    return this.isPartnerAvailable;
  }

  public setProjectId(projectId: number) {
    this.currentProjectId = projectId;
  }

  public getProjectId() {
    return this.currentProjectId ? this.currentProjectId : 0;
  }


}
