import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { HttpClient } from '@angular/common/http';
import { constants } from '../../assets/constants';

@Injectable()
export class LogService {

  private urlGetLog = constants.urlAPI + constants.endpoints.log.get;

  constructor(public http: HttpClient) { }

  public getLog(id): Observable<any> {
    return this.http.get(this.urlGetLog.replace(':id', id));
  }

}
