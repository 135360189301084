import { Component, OnInit, Input, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { GlobalService } from '../../../services/global.service';
import { AuthService } from '../../../services/auth.service';
import { EventsService } from '../../../services/events.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AlertsService } from '../../../services/alerts.service';
import { User, UserSession } from '../../../interfaces/user';
import { UsersService } from '../../../services/users.service';
import { NotificationsService } from '../../../services/notifications.service';
import { UserNotification } from '../../../interfaces/notification';
import { MediaMatcher } from '@angular/cdk/layout';
import { constants } from '../../../../assets/constants';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, AfterViewInit {

  title: any;
  logged: boolean;
  user: UserSession;
  notifications: UserNotification[];

  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;

  constructor(private globalService: GlobalService,
    private router: Router,
    private eventsService: EventsService,
    private alertsService: AlertsService,
    private notificationsService: NotificationsService,
    private authService: AuthService,
    changeDetectorRef: ChangeDetectorRef, media: MediaMatcher) {
    this.notifications = [];

    this.mobileQuery = media.matchMedia('(max-width: 800px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  ngOnInit() {
    this.checkIsLogged();

    this.globalService.pageTitleChangeEvent$.subscribe((params) => {
      this.title = params;
    });
    this.eventsService.event$.subscribe((params: any) => {
      if (params.key === 'login' && params.value === 'ok') {
        this.checkIsLogged();
        this.loadData();
      }
    });
    this.eventsService.event$.subscribe((params: any) => {
      if (params.key === 'logout' && params.value === 'ok') {
        this.logged = false;
        this.unloadData();
      } else {
      }
    });

    // Event listener for Notifications
    this.eventsService.event$.subscribe((params: any) => {
      if (params.key === 'newNotifications') {
        if (params.value === 'true') {
          this.getNotifications();
        }
      }
    });

  }

  ngAfterViewInit() {
    this.loadData();
  }


  private loadData() {
    this.getNotifications();

    this.notificationsService.getNotifications().subscribe(
      result => {
        // Copia array de notifications
        Object.assign(this.notifications, result);
        this.user = JSON.parse(localStorage.getItem('pmsession'));
      });

  }

  private unloadData() {
    this.user = null;
    this.notifications = [];
  }
  // private autoLoad(seconds: number) {
  //   setInterval(() => {
  //     this.loadData();
  //   }, seconds * 1000);
  // }

  closeSession() {
    this.authService.closeSession().subscribe(resp => {
      this.router.navigate(['/login']);
    });
    localStorage.clear();
  }

  private checkIsLogged() {
    this.authService.validateOrRefreshToken().subscribe(
      result => {
        this.logged = result.valid;
      });
  }

  public getNotifications() {
    this.notificationsService.getNotifications().subscribe(
      result => {
        // this.notifications.length = 0;
        // result.forEach(element => {
        //   this.notifications.push(element);
        // });

        // Copia array de notifications
        this.notifications.length = 0;
        Object.assign(this.notifications, result);

        // Comprueba que si esta en vista con chat abierto, no muestre notificaciones
        const currentProject = this.globalService.getProjectId();
        if (currentProject !== 0) {
          result.forEach(userNotification => {
            if (userNotification.notification.id === constants.notificationTypes.chat && userNotification.project.id === currentProject) {
              this.notificationsService.putNotifications([userNotification]).subscribe(result2 => {
                this.eventsService.doEvent({ key: 'newNotifications', value: 'true' });
              });
            }
          });
        }
      },
      error => {
        console.log('Error', error);
      }
    );
  }


  public getAvatarUrl() {
    if (this.user) {
      if (this.user.userImage) {
        // const hash = Math.floor(Math.random() * 1000000) + 1;
        const hash = JSON.parse(localStorage.getItem('pmsession')).refreshToken.slice(0, 10);

        return constants.urlUserAvatar.replace(':id', this.user.userId.toString()).replace(':file', this.user.userImage + '?h=' + hash.toString());
      } else {
        return;
      }
    } else {
      return;
    }
  }
  private navigateTo(projectId: number, formId?: number) {
    if (formId !== undefined) {
      this.router.navigateByUrl('/project/' + projectId + '/form/' + formId);
    } else {
      this.router.navigateByUrl('/project/' + projectId + '/info');
    }
  }
}
