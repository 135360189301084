import { Component, OnInit, TemplateRef, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { GlobalService } from '../../services/global.service';
import { User } from '../../interfaces/user';
import { UsersService } from '../../services/users.service';
import { TranslateService } from '@ngx-translate/core';
import { AlertsService } from '../../services/alerts.service';

@Component({
    selector: 'app-modal-remove-user',
    templateUrl: 'modal-remove-user.html',
})
export class ModalRemoveUserComponent {

    user: User;
    users: User[];

    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<ModalRemoveUserComponent>,
        private globalService: GlobalService,
        private usersService: UsersService,
        private router: Router,
        private translate: TranslateService,
        private alertsService: AlertsService) {

        this.user = data.user;
        this.users = data.users;
    }

    removeUser(user: User) {
        this.deleteUser(user);
        this.dialogRef.close();
    }

    modalRemoveUserCancel() {
        this.dialogRef.close();
    }

    public deleteUser(user?: User) {
        const userToDelete = user ? user : this.user;

        this.usersService.deleteUser(userToDelete.id).subscribe(
            result => {
                this.router.navigate(['/users']);
                this.translate.get('USERS.USER_DELETED').subscribe((translation) => {
                    this.alertsService.alertTrigger({ type: 'success', msg: translation });
                });
            },
            error => {
                console.log('Error', error);
            }
        );
    }
}

