import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { constants } from '../../assets/constants';

@Injectable()
export class OrdersService {

  private urlGetOrders = constants.urlAPI + constants.endpoints.orders.get;
  private urlGetSalesTaker = constants.urlAPI + constants.endpoints.orders.getSalesTaker;

  constructor(public http: HttpClient) { }

  public getOrders(parameters: any): Observable<any> {
    return this.http.get(this.urlGetOrders, { params: parameters });
  }

  public getSalesTaker(): Observable<any> {
    return this.http.get(this.urlGetSalesTaker);
  }

  // search='GSO-03881'&order=1&orderBy=1&page =1&quantity=50&status=1

  // public getProject(id): Observable<any> {
  //   return this.http.get(this.urlGetProject.replace(':id', id));
  // }

  // public putProject(project: Project): Observable<any> {
  //   const data = project;
  //   return this.http.put(this.urlGetProjects, data);
  // }

  // public postProject(project: Project): Observable<any> {
  //   const data = project;
  //   return this.http.post(this.urlGetProjects, data);
  // }

}
