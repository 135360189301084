import { OrderLine } from './../../../../interfaces/order';
import { Component, OnInit, Input } from '@angular/core';
import { Order } from '../../../../interfaces/order';
import { constants } from '../../../../../assets/constants';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-lines',
  templateUrl: './lines.component.html',
  styleUrls: ['./lines.component.scss']
})
export class LinesComponent implements OnInit {

  @Input() order: Order;
  displayedColumns: string[] = ['lineNum', 'itemName', 'quantity', 'status'];

  constructor(private translate: TranslateService) {}

  ngOnInit() {
  }

  private statusToText(statusId: number) {
    statusId = statusId === undefined || statusId === null ? constants.defaultLineStatus : statusId;
    return constants.orderLineStatus.find(s => s.id === statusId);
  }

  public showLineStatus(line: OrderLine){
    return !constants.orderLineStatusOverride.includes(line.itemId);
  }

}

