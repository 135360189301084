import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { WelcomeService } from '../../services/welcome.service';
import { WelcomeData } from '../../interfaces/welcome';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginData } from '../../interfaces/logindata';
import { constants } from '../../../assets/constants';
import { AuthService } from '../../services/auth.service';
import { AlertsService } from '../../services/alerts.service';
import { EventsService } from '../../services/events.service';
import { FormControl, Validators } from '@angular/forms';
import { LoginErrorStateMatcher } from '../login/login.component';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  password: string;
  repeatPassword: string;
  passwordMatch: boolean;
  hash: string;
  userEmail: string;
  errorUser: boolean;

  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);

  matcher = new LoginErrorStateMatcher();

  constructor(private translate: TranslateService,
    private authService: AuthService,
    private alertsService: AlertsService,
    private router: Router) {
    this.passwordMatch = true;
    this.userEmail = '';
    this.errorUser = false;

    if (this.authService.isLogged()) {
      this.router.navigate(['/']);
    }
  }

  ngOnInit() {
  }

  onSubmit() {
    this.errorUser = false;
    if (!this.userEmail || this.userEmail === '') {
      this.errorUser = true;
    }

    if (!this.errorUser) {
      this.authService.sendResetPasswordEmail(this.userEmail).subscribe(
        result => {
          this.translate.get('RESET_PASSWORD.EMAIL_SENT').subscribe((translation) => {
            this.alertsService.alertTrigger({ type: 'success', msg: translation, button: 'Ok' });
          });
          this.router.navigateByUrl('/login');
        },
        error => {
          this.translate.get('RESET_PASSWORD.EMAIL_SENT').subscribe((translation) => {
            this.alertsService.alertTrigger({ type: 'success', msg: translation, button: 'Ok' });
          });
          this.router.navigateByUrl('/login');
        }
      );
    }

  }

}
