import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { HttpClient } from '@angular/common/http';
import { constants } from '../../assets/constants';
import { UserNotification } from '../interfaces/notification';

@Injectable()
export class NotificationsService {

  private urlGetNotifications = constants.urlAPI + constants.endpoints.notifications.get;
  private urlPutNotifications = constants.urlAPI + constants.endpoints.notifications.put;

  constructor(public http: HttpClient) { }

  public getNotifications(): Observable<any> {
    return this.http.get(this.urlGetNotifications);
  }

  public putNotifications(notifications: UserNotification[]): Observable<any> {
    const data = notifications;
    return this.http.put(this.urlPutNotifications, data);
  }

  public manageNotifications(notifications: UserNotification[], formId?: number) {
    const sendNotifications: UserNotification[] = [];
    if (notifications) {
      notifications.forEach(notification => {
        if (notification.notification.formId === formId || notification.notification.formId === null) {
          sendNotifications.push(notification);

          const index = notifications.findIndex(x => x.notification.id === notification.id);
          notifications.splice(index, 1);
        }
      });
      if (sendNotifications.length > 0) {
        this.putNotifications(sendNotifications).subscribe(
          result => { },
          error => {
            console.log('Error', error);
          }
        );
      }
    }
  }
}
