import { DMOrderItem } from './../interfaces/document-manager';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { constants } from '../../assets/constants';

@Injectable({
  providedIn: 'root'
})
export class DocumentManagerService {

  private urlGetOrderList = constants.urlAPI + constants.endpoints.documentManager.getList;
  private urlGetOrder = constants.urlAPI + constants.endpoints.documentManager.getDetail;
  private urlPostOrder = constants.urlAPI + constants.endpoints.documentManager.postDetail;
  private urlPutOrder = constants.urlAPI + constants.endpoints.documentManager.putDetail;

  constructor(public http: HttpClient) { }

  public getOrders(): Observable<any> {
    return this.http.get(this.urlGetOrderList);
  }

  public getOrder(id): Observable<any> {
    return this.http.get(this.urlGetOrder.replace(':id', id));
  }

  public postOrder(project: DMOrderItem): Observable<any> {
    const data = project;
    return this.http.post(this.urlPostOrder, data);
  }

  public putOrder(project: DMOrderItem): Observable<any> {
    const data = project;
    return this.http.put(this.urlPutOrder, data);
  }

}
