import { Component, OnInit, Input } from '@angular/core';
import { Order } from '../../../../interfaces/order';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit {

  @Input() order: Order;

  constructor(public translateService: TranslateService) { }

  ngOnInit() {
  }

  getPercentage(amount: number) {
    if(this.order.totalAmount !== 0){
      return amount * 100 / this.order.totalAmount;
    } else {
      return -1;
    }
  }

  public hasPayMethod(key: string): boolean {
    return this.translateService.instant(key) !== key;
  }
}
