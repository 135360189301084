const environment = 'dev'; // dev / test
const httpString = 'http'; // dev / test

export const constants = {
  appVersion: '1.3.15',
  appId: 1,
  localUrlAPI: 'http://localhost:4200',
  urlAPI: httpString + '://' + environment + 'apipms.pramacloud.com',
  urlUserAvatar: httpString + '://' + environment + 'pms.pramacloud.com/public/users/:id/avatar/:file',
  urlAPP: httpString + '://' + environment + 'customerjourney.pramacloud.com',
  urlTempFiles: '/public/temp/',
  urlGalleryUrl: httpString + '://' + environment + 'apipms.pramacloud.com/customer-journey/tl/file/',
  urlGalleryOrderUrl: httpString + '://' + environment + 'apipms.pramacloud.com/customer-journey/order/file/',
  urlGalleryUrlDownload: httpString + '://' + environment + 'apipms.pramacloud.com/customer-journey/tl/file/download/',
  urlGalleryUrlBase64: httpString + '://' + environment + 'apipms.pramacloud.com/customer-journey/tl/file/view/',
  urlProjectPdfDownload: httpString + '://' + environment + 'apipms.pramacloud.com/customer-journey/tl/project/:project_id/form/:form_id/pdf',

  endpoints: {
    login: {
      post: '/token/user'
    },
    validateToken: {
      post: '/token/user/valid'
    },
    refreshToken: {
      post: '/token/user/renew'
    },
    media: {
      post: '/customer-journey/media/upload',
    },
    profiles: {
      get: '/customer-journey/profile'
    },
    profile: {
      post: '/customer-journey/profile',
      put: '/customer-journey/profile',
      delete: '/customer-journey/profile/:id'
    },
    roles: {
      get: '/customer-journey/role'
    },
    projects: {
      get: '/customer-journey/project'
    },
    project: {
      get: '/customer-journey/project/:id',
      post: '/customer-journey/project',
      put: '/customer-journey/project',
      getProductTypes: '/customer-journey/project/product-type',
      getPavigymCompanies: '/customer-journey/project/pavigym-company'
    },
    user: {
      get: '/customer-journey/user/:id',
      post: '/customer-journey/user',
      put: '/customer-journey/user',
      delete: '/customer-journey/user/:id',
      putPassword: '/customer-journey/user/set-password',
      getWelcomeUser: '/customer-journey/user/hash'
    },
    users: {
      get: '/customer-journey/user',
      getByProfile: '/customer-journey/user/profile/:id',
      getByProfileBasic: '/customer-journey/user/profile/:id/basic',
    },
    userStatus: {
      get: '/customer-journey/user/status'
    },
    customers: {
      get: '/customer-journey/user/profile/2'
    },
    chat: {
      get: '/customer-journey/chat/:id',
      post: '/customer-journey/chat'
    },
    log: {
      get: '/customer-journey/log/:id'
    },
    files: {
      get: '/customer-journey/files/:id',
      post: '/customer-journey/files',
      delete: '/customer-journey/tl/file'
    },
    forms: {
      get: '/customer-journey/form/template'
    },
    form: {
      get: '/customer-journey/form/template/:id',
      post: '/customer-journey/form/template',
      put: '/customer-journey/form/template',
      getInputTypes: '/customer-journey/form/inputs',

      getProjectForm: '/customer-journey/tl/project/:id/form/:fid',
      postProjectForm: '/customer-journey/tl/form',
      validateProjectForm: '/customer-journey/tl/form/validate',
      getFormsAvailable: '/customer-journey/project/:id/forms-availables'
    },
    orders: {
      get: '/customer-journey/order',
      getSalesTaker: '/customer-journey/order/workersalestaker'
    },
    notifications: {
      get: '/customer-journey/notification',
      put: '/customer-journey/notification'
    },
    resetPassword: {
      get: '/customer-journey/user/recovery-password'
    },
    cronDataVerify: {
      get: '/customer-journey/project/changes/:id'
    },
    tasks: {
      getList: '/customer-journey/task',
      get: '/customer-journey/task/:id',
      viewed: '/customer-journey/task/viewed',
      put: '/customer-journey/task'
    },
    config: {
      get: '/customer-journey/user/options',
      post: '/customer-journey/user/options'
    },    
    documentManager: {
      getList: '/customer-journey/order/items',
      getDetail: '/customer-journey/order/item/:id',
      postDetail: '/customer-journey/order/item',
      putDetail: '/customer-journey/order/item'
    }
  },
  keySession: 'pmsession',
  keyRoles: 'pmroles',
  roles: {

    cj_user: 1,
    cj_user_create: 2,
    cj_user_detail: 3,
    cj_user_list: 4,
    cj_user_modify: 5,
    cj_profile_create: 6,
    cj_profile_delete: 7,
    cj_profile_detail: 8,
    cj_profile_list: 9,
    cj_profile_modify: 10,
    cj_form_create: 11,
    cj_form_detail: 12,
    cj_form_list: 13,
    cj_form_modify: 14,
    cj_project_create: 100,
    cj_customer_create: 20,
    cj_customer_detail: 21,
    cj_customer_list: 22,
    cj_customer_modify: 23,

    cj_tl_project_info_view: 101,
    cj_tl_project_info_modify: 102,

    cj_tl_opening_date_view: 103,
    cj_tl_opening_date_modify: 104,
    cj_tl_opening_date_gallery_view: 105,
    cj_tl_opening_date_gallery_upload: 106,

    cj_tl_kom_date_validation: 110,
    cj_tl_kom_date_view: 111,
    cj_tl_kom_date_modify: 112,
    cj_tl_kom_form_validation: 113,
    cj_tl_kom_form_view: 114,
    cj_tl_kom_form_modify: 115,
    cj_tl_kom_report: 116,
    cj_tl_kom_gallery_view: 117,
    cj_tl_kom_gallery_upload: 118,

    cj_tl_install_date_validation: 120,
    cj_tl_install_date_view: 121,
    cj_tl_install_date_modify: 122,
    cj_tl_install_pdi_view: 123,
    cj_tl_install_pdi_upload: 124,
    cj_tl_install_pic_view: 127,
    cj_tl_install_pic_upload: 128,
    cj_tl_install_validation: 190,
    cj_tl_install_view: 191,
    cj_tl_install_modify: 192,

    cj_tl_installation_pcc_validation: 130,
    cj_tl_installation_pcc_view: 131,
    cj_tl_installation_pcc_modify: 132,
    cj_tl_installation_pcc_report: 133,

    cj_tl_installation_cc_validation: 134,
    cj_tl_installation_cc_view: 135,
    cj_tl_installation_cc_modify: 136,
    cj_tl_installation_cc_report: 137,

    cj_tl_installation_completion_gallery_view: 138,
    cj_tl_installation_completion_gallery_upload: 139,

    cj_tl_first_date_validation: 140,
    cj_tl_first_date_view: 141,
    cj_tl_first_date_modify: 142,
    cj_tl_first_form_validation: 143,
    cj_tl_first_form_view: 144,
    cj_tl_first_form_modify: 145,
    cj_tl_first_report: 146,
    cj_tl_first_gallery_view: 147,
    cj_tl_first_gallery_upload: 148,
    cj_tl_galleries_delete_file: 50,

    cj_tl_construction_works_validation: 150,
    cj_tl_construction_works_view: 151,
    cj_tl_construction_works_modify: 152,
    cj_tl_construction_works_gallery_view: 153,
    cj_tl_construction_works_gallery_upload: 154,

    cj_tl_dates_validation: 160,
    cj_tl_dates_view: 161,
    cj_tl_dates_modify: 162,

    cj_tl_training_date_validation: 170,
    cj_tl_training_date_view: 171,
    cj_tl_training_date_modify: 172,

    cj_tl_roadmap_date_validation: 180,
    cj_tl_roadmap_date_view: 181,
    cj_tl_roadmap_date_modify: 182,

    cj_tl_delivery_date_validation: 200,
    cj_tl_delivery_date_view: 201,
    cj_tl_delivery_date_modify: 202,

    cj_tl_delivery_gallery_view: 210,
    cj_tl_delivery_gallery_upload: 211,

    cj_tl_delivery_form_validation: 220,
    cj_tl_delivery_form_view: 221,
    cj_tl_delivery_form_modify: 222,

    cj_sales_order_info_view: 300,
    cj_sales_order_advanced_filter: 301,

    cj_sales_task_manager: 314,
    
    cj_sales_order_items_create: 310,
    cj_sales_order_items_detail: 311,
    cj_sales_order_items_list: 312,
    cj_sales_order_items_modify: 313

  },
  inputTypes: [
    // { id: 1, type: 'input_text', name: 'Input text', inputType: { id: 1, name: 'CODE' } },
    // { id: 2, type: 'input_number', name: 'Input number', inputType: { id: 1, name: 'CODE' } },
    // { id: 3, type: 'input_email', name: 'Input email', inputType: { id: 1, name: 'CODE' } },
    // { id: 4, type: 'radio_button', name: 'Radio button', inputType: { id: 1, name: 'CODE' } },
    // { id: 5, type: 'checkbox', name: 'Checkbox', inputType: { id: 1, name: 'CODE' } },
    // { id: 6, type: 'combobox', name: 'Combobox', inputType: { id: 1, name: 'CODE' } },
    // { id: 7, type: 'textarea', name: 'Textarea', inputType: { id: 1, name: 'CODE' } },
    // { id: 8, type: 'file', name: 'File', inputType: { id: 1, name: 'CODE' } },
    // { id: 9, type: 'signature', name: 'Signature', inputType: { id: 1, name: 'CODE' } },
    // { id: 10, type: 'datepicker', name: 'Datepicker', inputType: { id: 1, name: 'CODE' } },
    // { id: 11, type: 'spacer', name: 'Spacer', inputType: { id: 2, name: 'HTML' } },
    // { id: 12, type: 'title', name: 'Title', inputType: { id: 2, name: 'HTML' } },
    // { id: 13, type: 'blank_element', name: 'Blank element', inputType: { id: 2, name: 'HTML' } },
    // { id: 14, type: 'text', name: 'Text', inputType: { id: 2, name: 'HTML' } },
    // { id: 15, type: 'agentrole', name: 'Agent and Role', inputType: { id: 1, name: 'CODE' } },
    // { id: 16, type: 'calendar', name: 'Calendar', inputType: { id: 1, name: 'CODE' } },
  ],
  forms: {
    FORM_KOM_PROPOSAL: 1,
    FORM_OPENING_CONFIRMATION: 2,
    FORM_INSTALLATION_PROPOSAL: 3,
    FORM_KOM1: 4,
    FORM_ORDER_GALLERY_UPLOAD: 31
  },
  languages: [
    { id: 1, name: 'Spanish', cultureInfo: 'es-ES' },
    { id: 2, name: 'English', cultureInfo: 'en-GB' },
    { id: 3, name: 'Deutsch', cultureInfo: 'de-DE' },
    { id: 4, name: 'French', cultureInfo: 'fr-FR' }
  ],
  notificationTypes: {
    chat: 52
  },

  timelineEvents: {
    KICKOFF_MEETING_PROPOSAL: 1,
    ROADMAP: 2,
    DISPATCH: 3,
    DELIVERY: 4,
    FIRST_SITE_VISIT_PROPOSAL: 5,
    INSTALLATION: 6,
    TRAINING: 7,
    OPENING: 8
  },

  orderBy: [
    { id: 1, by: 'ORDER_DATE' },
    { id: 2, by: 'ORDER_CODE' },
    { id: 3, by: 'ORDER_TOTAL_AMOUNT' },
    { id: 4, by: 'ORDER_SHIPPING_DATE' },
  ],

  orderDirection: [
    { id: 1, direction: 'ASC' },
    { id: 2, direction: 'DESC' }
  ],

  orderStatus: [
    { id: 1, status: 'ORDER_REGISTERED', icon: 'clipboard-check' },
    { id: 2, status: 'DESIGN_PRODUCTION_FILES_GENERATED', icon: 'android-studio' },
    { id: 3, status: 'PRODUCTION_STARTED', icon: 'factory' },
    { id: 4, status: 'CONFIRMED_DELIVERY_DATE', icon: 'package-variant-closed' },
    { id: 5, status: 'ORDER_FULLY_DELIVERED', icon: 'check' },
    { id: 6, status: 'ORDER_DELAYED', icon: 'clock-alert', alert: true },
    { id: 10, status: 'ONGOING_ORDERS' },
    { id: -1, status: 'UNKNOWN_STATUS', icon: 'help' }
  ],

  orderCompanies: [
    { id: 0, name: 'ALL', value: '' },
    { id: 1, name: 'DACH', value: 'DACH' },
    { id: 2, name: 'PAC', value: 'PAC' },
    { id: 3, name: 'PAU', value: 'PAU' },
    { id: 4, name: 'PCG', value: 'PCG' },
  ],

  orderLineStatus: [
    { id: 0, status: 'STATUS_LINE_TITLE_0' },
    { id: 1, status: 'STATUS_LINE_TITLE_1' },
    { id: 2, status: 'STATUS_LINE_TITLE_2' },
    { id: 3, status: 'STATUS_LINE_TITLE_3' },
    { id: 4, status: 'STATUS_LINE_TITLE_4' },
  ],

  // Codigos de linea que no mostrarán estado
  orderLineStatusOverride: [
    '000454',
    '003305',
    '004265',
    '004266',
    '002294',
    '000529',
    '001303',
    '000457',
    '004264',
    '004267',
    '004268',
    '004269',
    '000546',
    '005182',
    '005147',
    '005178',
    '005179',
    '005150',
    '005151',
    '005152',
    '005153',
    '005154',
    '005155',
    '005158'
  ],

  taskRemindOptions: [
    { id: 1, hours: 1 },
    { id: 2, hours: 3 },
    { id: 3, hours: 8 },
    { id: 4, hours: 24 },
    { id: 5, hours: 48 },
    { id: 6, hours: 168 }
  ],
  taskTrackOptions: [
    { id: 1, hours: 1 },
    { id: 2, hours: 3 },
    { id: 3, hours: 8 },
    { id: 4, hours: 24 },
    { id: 5, hours: 48 },
    { id: 6, hours: 168 }
  ],

  defaultLineStatus: 0,
};
