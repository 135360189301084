import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { constants } from '../../assets/constants';
import { ProjectForm } from '../interfaces/projectform';
import { Project } from '../interfaces/project';

@Injectable()
export class ProjectFormsService {

  private urlGetProject = constants.urlAPI + constants.endpoints.project.get;
  private urlPutProject = constants.urlAPI + constants.endpoints.project.put;

  private urlGetProjectForm = constants.urlAPI + constants.endpoints.project.get;
  private urlPutProjectForm = constants.urlAPI + constants.endpoints.project.put;
  private urlPostProjectForm = constants.urlAPI + constants.endpoints.project.post;

  constructor(public http: HttpClient) { }

  // public getUsers(): Observable<any> {
  //   return this.http.get(this.urlGetUsers);
  // }

  public getProject(): Observable<any> {
    return this.http.get(this.urlGetProject);
  }
  public putProject(project: Project): Observable<any> {
    const data = project;
    return this.http.put(this.urlPutProject, data);
  }
  public postProject(project: Project): Observable<any> {
    const data = project;
    return this.http.post(this.urlPutProject, data);
  }

  public getForm(id): Observable<any> {
    return this.http.get(this.urlGetProjectForm.replace(':id', id));
  }

  public putForm(form: ProjectForm): Observable<any> {
    const data = form;
    return this.http.put(this.urlPutProjectForm, data);
  }

  public postForm(form: ProjectForm): Observable<any> {
    const data = form;
    return this.http.post(this.urlPostProjectForm, data);
  }

  // public deleteUser(id: number): Observable<any> {
  //   return this.http.delete(this.urlDeleteUser.replace(':id', id.toString()));
  // }

  // public getUserStatus(): Observable<any> {
  //   return this.http.get(this.urlGetUserStatus);
  // }

}
