import { Component, OnInit, TemplateRef, Inject, SecurityContext } from '@angular/core';
import { constants } from '../../../../assets/constants';
import { ActivatedRoute, Router } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { GlobalService } from '../../../services/global.service';
import { TranslateService } from '@ngx-translate/core';
import { AlertsService } from '../../../services/alerts.service';
import { ProjectFile } from '../../../interfaces/projectfile';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { FilesService } from '../../../services/files.service';
import { saveAs } from 'file-saver';

@Component({
    selector: 'app-modal-view-form',
    templateUrl: 'modal-view-form.html',
})
export class ModalViewFormComponent implements OnInit {

    projectId: number;
    formId: number;
    fileUrl;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<ModalViewFormComponent>,
        private globalService: GlobalService,
        private router: Router,
        private translate: TranslateService,
        private alertsService: AlertsService,
        private filesService: FilesService,
        private sanitizer: DomSanitizer) {

        this.projectId = data.project;
        this.formId = data.form;
    }

    ngOnInit(): void {
        this.getFormPdf();
    }

    private getFormPdf() {
        this.filesService.getFormPdf(this.projectId, this.formId).subscribe(
            result => {
                this.fileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(result.path);
                console.log(this.fileUrl);
            },
            error => {
                console.log('Error');
            }
        );
    }

    public securePath(path: string) {
        // return this.sanitizer.bypassSecurityTrustResourceUrl(this.file.path);
        // return this.sanitizer.bypassSecurityTrustResourceUrl(constants.urlGalleryUrl + this.file.uniqueId);
        // const path = this.sanitizer.sanitize(SecurityContext.RESOURCE_URL, this.sanitizer.bypassSecurityTrustResourceUrl(constants.urlGalleryUrl + this.file.uniqueId));
        return this.sanitizer.bypassSecurityTrustResourceUrl(path);
    }

}

