import { AlertsService } from './../../services/alerts.service';
import { GlobalService } from './../../services/global.service';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DMOrderItem } from './../../interfaces/document-manager';
import { Component, OnInit } from '@angular/core';
import { DocumentManagerService } from '../../services/document-manager.service';
import { constants } from '../../../assets/constants';

@Component({
  selector: 'app-document-manager-detail',
  templateUrl: './document-manager-detail.component.html',
  styleUrls: ['./document-manager-detail.component.scss']
})
export class DocumentManagerDetailComponent implements OnInit {

  order: DMOrderItem = {
    id: 0,
    code: '',
    name: '',
    categories: []
  };

  constants;

  isNew: Boolean = false;

  constructor(private route: ActivatedRoute,
    private translate: TranslateService,
    private alertsService: AlertsService,
    public globalService: GlobalService,
    private documentManagerService: DocumentManagerService) { }

  ngOnInit() {
    if (this.route.snapshot.data.order === 'new') {
      this.isNew = true;

      this.translate.get('ORDERS.TITLE_NEW').subscribe((translation) => {
        this.globalService.changePageTitle([translation]);
      });
    } else {
      this.order = this.route.snapshot.data.order;
      this.globalService.changePageTitle([this.order.name]);
    }

    this.constants = constants;
  }

  public saveOrderItem() {
    this.documentManagerService.putOrder(this.order).subscribe(
      result => {
        this.translate.get('ORDERS.ORDER_ITEM_SAVED').subscribe((translation) => {
          this.alertsService.alertTrigger({ type: 'success', msg: translation });
        });
      },
      error => {
        console.log('Error', error);
      }
    );
  }

  public createOrderItem() {
    this.order.id = 0;
    this.documentManagerService.postOrder(this.order).subscribe(
      result => {
        if(result){

          this.translate.get('ORDERS.ORDER_ITEM_CREATED').subscribe((translation) => {
            this.alertsService.alertTrigger({ type: 'success', msg: translation });
          });
          
          this.order = result;
          this.isNew = false;
        }else{
          this.translate.get('ORDERS.ORDER_ITEM_ERROR_CODE').subscribe((translation) => {
            this.alertsService.alertTrigger({ type: 'error', msg: translation });
          });
        }
      },
      error => {
        console.log('Error', error);
      }
    );
  }

  onSubmit() {
    if (this.isNew) {
      this.createOrderItem();
    } else {
      this.saveOrderItem();
    }
  }

  getLanguageResource(catId: number, langId: number){
    let value = this.order.categories.find(cat => cat.id === catId).dictionary.resources.find(res => res.language.id === langId).value;
    return value;
  }

  setLanguageResource(catId: number, langId: number, $event){
    this.order.categories.find(cat => cat.id === catId).dictionary.resources.find(res => res.language.id === langId).value = $event;    
  }

  countFilledLanguages(langId: number){
    let count = 0;
    this.order.categories.forEach(cat =>{
      if(cat.dictionary.resources.find(res => res.language.id === langId).value !== ""){
        count++;
      }
    });
    return count;
  }

}
