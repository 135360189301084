import { Component, OnInit, Inject, ViewChild, TemplateRef } from '@angular/core';
import { Task } from '../../../interfaces/task';
import { TasksService } from '../../../services/tasks.service';
import { MatBottomSheet, MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { ModalTaskDetailComponent } from './modal-task-detail.component';
import { EventsService } from '../../../services/events.service';

@Component({
  selector: 'app-tasks',
  templateUrl: './tasks.component.html',
  styleUrls: ['./tasks.component.scss']
})
export class TasksComponent implements OnInit {

  public taskList: Task[];
  public trackedTaskList: Task[];

  constructor(@Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
    private tasksService: TasksService,
    private bottomSheet: MatBottomSheet,
    private events: EventsService) {
    this.taskList = [];
    this.trackedTaskList = [];

    this.events.event$.subscribe((params: any) => {
      if (params.key === 'tasklist') {
        if (params.value === 'reload') {
          this.getTaskList(true);
        }
      }
    });
  }

  ngOnInit() {    
    this.getTaskList(false);
  }

  openTasksView(): void {
    this.bottomSheet.open(TaskListComponent, {
      data: {
        taskList: this.taskList,
        trackedTaskList: this.trackedTaskList,
      },
      panelClass: 'task-list-bottomsheet'
    });    
  }

  private getTaskList(openSheet: boolean = false) {
    console.log(this.bottomSheet);
    this.tasksService.getTasks().subscribe(
      result => {
        this.taskList = result.filter(x => x.remind === null);
        this.trackedTaskList = result.filter(x => x.remind !== null);
        this.data.taskList = this.taskList;
        this.data.trackedTaskList = this.trackedTaskList;
                
        if(openSheet){
          this.bottomSheet.dismiss();
          this.openTasksView();
        }
      },
      error => {
        console.log('Error');
      }
    );
  }

  public getUnreadTasks() {
    return this.taskList.filter(x => x.viewed === true).length;
  }

}

@Component({
  selector: 'app-tasks-list',
  templateUrl: './tasks-list.component.html',
})
export class TaskListComponent {

  @ViewChild('taskModal', { static: false }) taskModal: TemplateRef<any>;

  constructor(private bottomSheetRef: MatBottomSheetRef<TaskListComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
    public dialog: MatDialog,
    private tasksService: TasksService,
    private events: EventsService) {
  }

  // openLink(event: MouseEvent): void {
  //   this.bottomSheetRef.dismiss();
  //   event.preventDefault();
  // }

  private openTaskModal($event, task: Task) {
    this.bottomSheetRef.dismiss();
    $event.preventDefault();

    this.dialog.open(ModalTaskDetailComponent, {
      data: task,
      minWidth: '60vw',
      maxWidth: '100vw',
      maxHeight: '100vh',
      autoFocus: false
    });

  }

  private unTrackTask(task: Task) {
    task.action = 3;
    task.remind = null;
    this.tasksService.putTask(task).subscribe(
      result => {        
        this.events.doEvent({key: 'tasklist', value: 'reload'});
      },
      error => {
        console.log('Error');
      }
    );
  }

}
