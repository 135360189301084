import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AsyncPipe } from '@angular/common';

import { FlexLayoutModule } from '@angular/flex-layout';

import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es';
registerLocaleData(localeEs, 'es');

// ngx-bootstrap
import { ModalModule } from 'ngx-bootstrap';
import { AlertModule } from 'ngx-bootstrap';

// Pagination
import { NgxPaginationModule } from 'ngx-pagination';

// Other modules
import { SignaturePadModule } from 'angular2-signaturepad';
import { NgxPrintModule } from 'ngx-print';
import { AngularEditorModule } from '@kolkov/angular-editor';

// Angular Material
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatCardModule } from '@angular/material/card';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatTableModule } from '@angular/material/table';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips';
import { MatDividerModule } from '@angular/material/divider';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatMenuModule } from '@angular/material/menu';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatBadgeModule } from '@angular/material/badge';
import { MatTabsModule } from '@angular/material/tabs';
import { MatStepperModule } from '@angular/material/stepper';
import { MatBottomSheetModule, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';

import { DragDropModule } from '@angular/cdk/drag-drop';

import * as moment from 'moment';

// Services
import { GlobalService } from './services/global.service';
import { UsersService } from './services/users.service';
import { AlertsService } from './services/alerts.service';
import { ProfilesService } from './services/profiles.service';
import { RolesService } from './services/roles.service';
import { AuthService } from './services/auth.service';
import { EventsService } from './services/events.service';
import { ProjectFormsService } from './services/projectforms.service';
import { ProjectsService } from './services/projects.service';
import { CustomersService } from './services/customers.service';
import { ChatService } from './services/chat.service';
import { LogService } from './services/log.service';
import { FilesService } from './services/files.service';
import { FormManagerService } from './services/formmanager.service';
import { LanguagesService } from './services/languages.service';
import { FormsService } from './services/forms.service';
import { NotificationsService } from './services/notifications.service';
import { LoadingService } from './services/loading.service';
import { WelcomeService } from './services/welcome.service';
import { OrdersService } from './services/orders.service';
import { TasksService } from './services/tasks.service';
import { DocumentManagerService } from './services/document-manager.service';

// Guards
import { AuthGuard } from './guards/auth-guard';

// Interceptors
import { AuthInterceptor } from './interceptors/auth-interceptor';

// Pipes
import { YesNoPipe } from './pipes/yesno.pipe';
import { ApptypePipe } from './pipes/apptype.pipe';
import { UserStatusPipe } from './pipes/userstatus.pipe';

// Components
import { AlertsComponent } from './components/common/alerts/alerts.component';
import { UsersComponent } from './components/users/users.component';
import { CustomersComponent } from './components/users/customers.component';
import { HeaderComponent } from './components/common/header/header.component';
import { SidebarComponent } from './components/common/sidebar/sidebar.component';
import { UserDetailComponent } from './components/users/user-detail.component';
import { CustomerDetailComponent } from './components/users/customer-detail.component';
import { ProfilesComponent } from './components/profiles/profiles.component';
import { LoginComponent } from './components/login/login.component';
import { FooterComponent } from './components/common/footer/footer.component';
import { HomeComponent } from './components/home/home.component';
import { TimelineComponent } from './components/common/timeline/timeline.component';
import { MainlogComponent } from './components/partials/mainlog/mainlog.component';
import { NotificationListComponent } from './components/partials/notification-list/notification-list.component';
import { MainchatComponent } from './components/partials/mainchat/mainchat.component';
import { FormViewComponent } from './components/project/form-view/form-view.component';
import { FormManagerViewComponent } from './components/partials/form-view/form-view.component';
import { SignatureComponent } from './components/partials/signature/signature.component';
import { LogViewComponent } from './components/partials/log-view/log-view.component';

import { ProjectInfoComponent } from './components/project/info/info.component';
import { ProjectListComponent } from './components/project/project-list/project-list.component';
import { ImageUploaderComponent } from './components/partials/image-uploader/image-uploader.component';
import { FormManagerListComponent } from './components/form-manager/list/list.component';
import { FormManagerEditComponent } from './components/form-manager/edit/edit.component';
import { LoadingComponent } from './components/common/loading/loading.component';
import { FileViewComponent } from './components/partials/file-view/file-view.component';
import { GanttComponent } from './components/common/gantt/gantt.component';
import { UserSettingsComponent } from './components/users/user-settings.component';
import { WelcomeComponent } from './components/welcome/welcome.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { RefreshComponent } from './components/common/refresh/refresh.component';
import { TutorialModalComponent } from './components/common/tutorial/tutorial.component';

import { OrderListComponent } from './components/order/order-list/order-list.component';
import { OrderDetailComponent } from './components/order/order-detail/order-detail.component';
import { PaymentComponent } from './components/order/partials/payment/payment.component';
import { StatusComponent } from './components/order/partials/status/status.component';
import { LinesComponent } from './components/order/partials/lines/lines.component';
import { DownloadsComponent } from './components/order/partials/downloads/downloads.component';
import { SettingsComponent } from './components/settings/settings.component';
import { TasksComponent } from './components/common/tasks/tasks.component';
import { TaskListComponent } from './components/common/tasks/tasks.component';
import { ModalTaskDetailComponent } from './components/common/tasks/modal-task-detail.component';

import { DocumentManagerListComponent } from './components/document-manager/document-manager-list.component';
import { DocumentManagerDetailComponent } from './components/document-manager/document-manager-detail.component';


// Modal Components
import { ModalRemoveUserComponent } from './components/users/modal-remove-user.component';
import { ModalRemoveProfileComponent } from './components/profiles/modal-remove-profile.component';
import { ModalRemoveFileComponent } from './components/partials/file-view/modal-remove-file.component';
import { ModalValidateFormComponent } from './components/project/form-view/modal-validate-form.component';
import { ModalViewFileComponent } from './components/partials/file-view/modal-view-file.component';
import { ModalViewFormComponent } from './components/partials/file-view/modal-view-form.component';
import { ModalDownloadFileComponent } from './components/partials/file-view/modal-download-file.component';


// Resolvers
import { UserListResolver } from './resolvers/user-list.resolver';
import { UserDetailResolver } from './resolvers/user-detail.resolver';
import { UserSettingsResolver } from './resolvers/user-settings.resolver';
import { ProfileListResolver } from './resolvers/profile-list.resolver';
import { GlobalResolver } from './resolvers/global.resolver';
import { ProjectListResolver } from './resolvers/project-list.resolver';
import { ProjectInfoResolver } from './resolvers/project-info.resolver';
import { CustomerListResolver } from './resolvers/customer-list.resolver';
import { ChatResolver } from './resolvers/chat.resolver';
import { LogResolver } from './resolvers/log.resolver';
import { FileListResolver } from './resolvers/file-list.resolver';
import { ProjectFormResolver } from './resolvers/project-form.resolver';
import { FormManagerListResolver } from './resolvers/form-manager-list.resolver';
import { FormManagerEditResolver } from './resolvers/form-manager-edit.resolver';
import { InputTypesResolver } from './resolvers/input-types.resolver';
import { PavigymCompaniesResolver } from './resolvers/pavicompanies.resolver';
import { ProjectFormDataResolver } from './resolvers/project-form-data.resolver';
import { NotificationsResolver } from './resolvers/notifications.resolver';
import { FormsAvailableResolver } from './resolvers/forms-available.resolver';
import { SalesTakersResolver } from './resolvers/salestakers.resolver';
import { DMOrderListResolver } from './resolvers/dm-order-list.resolver';
import { DMOrderDetailResolver } from './resolvers/dm-order-detail.resolver';

@NgModule({
  declarations: [
    AppComponent,
    UsersComponent,
    CustomersComponent,
    HeaderComponent,
    UserDetailComponent,
    CustomerDetailComponent,
    AlertsComponent,
    ProfilesComponent,
    YesNoPipe,
    LoginComponent,
    SidebarComponent,
    FooterComponent,
    HomeComponent,

    ModalRemoveProfileComponent,
    ModalRemoveFileComponent,
    ModalRemoveUserComponent,
    ModalValidateFormComponent,
    ModalViewFileComponent,
    ModalViewFormComponent,
    ModalDownloadFileComponent,
    TutorialModalComponent,
    TaskListComponent,
    ModalTaskDetailComponent,

    ApptypePipe,
    UserStatusPipe,
    TimelineComponent,
    MainlogComponent,
    NotificationListComponent,
    MainchatComponent,
    ProjectInfoComponent,
    ProjectListComponent,
    ImageUploaderComponent,
    FormViewComponent,
    FormManagerListComponent,
    FormManagerEditComponent,
    FormManagerViewComponent,
    SignatureComponent,
    LogViewComponent,
    FileViewComponent,
    GanttComponent,
    UserSettingsComponent,
    LoadingComponent,
    WelcomeComponent,
    ResetPasswordComponent,
    RefreshComponent,

    OrderListComponent,
    OrderDetailComponent,
    PaymentComponent,
    StatusComponent,
    LinesComponent,
    DownloadsComponent,
    SettingsComponent,
    TasksComponent,
    DocumentManagerListComponent,
    DocumentManagerDetailComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    ModalModule.forRoot(),
    AlertModule.forRoot(),
    NgxPaginationModule,
    FlexLayoutModule,
    SignaturePadModule,
    NgxPrintModule,
    AngularEditorModule,

    MatButtonModule,
    MatCheckboxModule,
    MatExpansionModule,
    MatRadioModule,
    MatSelectModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatCardModule,
    MatGridListModule,
    MatTableModule,
    MatAutocompleteModule,
    MatChipsModule,
    MatDividerModule,
    MatListModule,
    MatSidenavModule,
    MatToolbarModule,
    MatIconModule,
    MatProgressBarModule,
    MatPaginatorModule,
    MatSortModule,
    MatMenuModule,
    MatSnackBarModule,
    MatDialogModule,
    MatTooltipModule,
    MatButtonToggleModule,
    MatSlideToggleModule,
    MatBadgeModule,
    MatTabsModule,
    MatStepperModule,
    DragDropModule,
    MatBottomSheetModule

  ],
  providers: [
    GlobalService,
    UsersService,
    ProfilesService,
    RolesService,
    AlertsService,
    AuthService,
    EventsService,
    ProjectFormsService,
    ProjectsService,
    CustomersService,
    ChatService,
    LogService,
    FilesService,
    FormManagerService,
    LanguagesService,
    FormsService,
    NotificationsService,
    WelcomeService,
    OrdersService,
    TasksService,
    DocumentManagerService,

    UserListResolver,
    UserDetailResolver,
    ProfileListResolver,
    ProjectListResolver,
    ProjectInfoResolver,
    CustomerListResolver,
    ChatResolver,
    LogResolver,
    GlobalResolver,
    FileListResolver,
    ProjectFormResolver,
    FormManagerListResolver,
    FormManagerEditResolver,
    InputTypesResolver,
    PavigymCompaniesResolver,
    ProjectFormDataResolver,
    NotificationsResolver,
    UserSettingsResolver,
    FormsAvailableResolver,
    SalesTakersResolver,
    DMOrderListResolver,
    DMOrderDetailResolver,

    LoadingService,

    AuthGuard,

    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: MAT_DATE_LOCALE, useValue: 'es-ES' },
    { provide: MAT_BOTTOM_SHEET_DATA, useValue: {} }

  ],
  entryComponents: [
    ModalRemoveProfileComponent,
    ModalRemoveFileComponent,
    ModalRemoveUserComponent,
    ModalValidateFormComponent,
    ModalViewFileComponent,
    ModalViewFormComponent,
    ModalDownloadFileComponent,
    TutorialModalComponent,
    TaskListComponent,
    ModalTaskDetailComponent
  ],
  bootstrap: [AppComponent]
})

export class AppModule { }

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}