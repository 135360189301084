import { AuthService } from './../../services/auth.service';
import { GlobalService } from './../../services/global.service';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';
import { DMOrderItem } from './../../interfaces/document-manager';
import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-document-manager-list',
  templateUrl: './document-manager-list.component.html',
  styleUrls: ['./document-manager-list.component.scss']
})
export class DocumentManagerListComponent implements OnInit, AfterViewInit {
  
  orderList: DMOrderItem[];
  
  displayedColumns = ['id', 'code', 'name', 'actions'];
  dataSource = new MatTableDataSource;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  constructor(private route: ActivatedRoute,    
    private translate: TranslateService,
    private globalService: GlobalService,
    public authService: AuthService) { }

  ngOnInit() {
    this.orderList = this.route.snapshot.data.orderList;
  }

  ngAfterViewInit() {
    this.dataSource = new MatTableDataSource(this.orderList);
    this.dataSource.paginator = this.paginator;
    this.dataSource.paginator.firstPage();
    this.dataSource.sort = this.sort;
  }

}
