import { Injectable } from '@angular/core';
import { constants } from '../../assets/constants';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { GlobalService } from './global.service';
import { SessionToken } from '../interfaces/sessiontoken';
import { ProjectFile } from '../interfaces/projectfile';

@Injectable()
export class FilesService {

  private urlGetFiles = constants.urlAPI + constants.endpoints.files.get;
  private urlDeleteFile = constants.urlAPI + constants.endpoints.files.delete;
  private urlGalleryUrl = constants.urlGalleryUrl;
  private urlGalleryOrderUrl = constants.urlGalleryOrderUrl;
  private urlGalleryUrlDownload = constants.urlGalleryUrlDownload;
  private urlGalleryUrlBase64 = constants.urlGalleryUrlBase64;
  private urlProjectPdfDownload = constants.urlProjectPdfDownload;

  constructor(public http: HttpClient, public globalService: GlobalService) { }

  public getFiles(id): Observable<any> {
    return this.http.get(this.urlGetFiles.replace(':id', id));
  }

  public getFile(uniqueId: string): Observable<any> {
    return this.http.get(this.urlGalleryUrl + uniqueId);
  }
  public getOrderFile(uniqueId: string): Observable<any> {
    return this.http.get(this.urlGalleryOrderUrl + uniqueId);
  }

  public getFormPdf(projectId: number, formId: number): Observable<any> {
    const file = this.urlProjectPdfDownload.replace(':project_id', projectId.toString()).replace(':form_id', formId.toString());
    return this.http.get(file);
  }
  public getFileDownload(uniqueId: string): Observable<any> {
    return this.http.get(this.urlGalleryUrlDownload + uniqueId, {responseType: 'blob'});
  }

  public postFile(data: File[]): Observable<string> {
    return Observable.create(observer => {
      const url = constants.urlAPI + constants.endpoints.media.post;
      const formData: FormData = new FormData();
      const xhr: XMLHttpRequest = new XMLHttpRequest();
      const token: SessionToken = JSON.parse(localStorage.getItem('pmsession'));
      for (let i = 0; i < data.length; i++) {
        formData.append('files[]', data[i], data[i].name);
      }
      // formData.append('files', data);

      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            // observer.next(JSON.parse(xhr.response).resultFiles);
            observer.next(JSON.parse(xhr.response).resultFiles);
            observer.complete();
          } else {
            observer.error(xhr.response);
          }
        }
      };
      xhr.open('POST', url, true);
      xhr.setRequestHeader('Authorization', 'Bearer ' + token.token);
      xhr.send(formData);
    });
  }

  public deleteFiles(uniqueIds: string[]): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: uniqueIds
    };
    return this.http.delete(this.urlDeleteFile, options);
  }
}
