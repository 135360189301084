import { Component, OnInit, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, Validators, NgForm } from '@angular/forms';

import { UsersService } from '../../services/users.service';
import { User } from '../../interfaces/user';
import { Profile } from '../../interfaces/profile';
import { AlertsService } from '../../services/alerts.service';
import { TranslateService } from '@ngx-translate/core';
import { GlobalService } from '../../services/global.service';
import { constants } from '../../../assets/constants';
import { Status } from '../../interfaces/status';
import { DomSanitizer } from '@angular/platform-browser';
import { MatChipInputEvent } from '@angular/material/chips';
import { COMMA, ENTER } from '@angular/cdk/keycodes';

@Component({
  selector: 'app-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.scss']
})
export class UserDetailComponent implements OnInit {

  avatarPath: string;
  tempAvatar: string;

  constants;

  user: User = {
    id: null,
    email: '',
    password: '',
    status: 0,
    recoveryHash: '',
    creationDate: '',
    birthDate: '',
    refreshToken: '',
    refreshTokenExpires: '',
    name: '',
    lastName: '',
    country: '',
    experience: 0,
    gender: '',
    address: '',
    zip: '',
    city: '',
    allowNewsletter: true,
    allowNotifications: true,
    phone: '',
    profiles: [],
    avatar: '',
    language: constants.languages[1],
    userAX: []
  };

  isNew: Boolean = false;
  profiles: Profile[];
  userProfiles: number[];
  userStatusList: Status[];

  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  constructor(private route: ActivatedRoute,
    private router: Router,
    private usersService: UsersService,
    private alertsService: AlertsService,
    private translate: TranslateService,
    public globalService: GlobalService,
    public sanitizer: DomSanitizer) {
    this.userStatusList = this.route.snapshot.data.global.userStatusList;
  }

  ngOnInit() {
    if (this.route.snapshot.data.user === 'new') {
      this.isNew = true;

      this.translate.get('USERS.TITLE_NEW').subscribe((translation) => {
        this.globalService.changePageTitle([translation]);
      });
    } else {
      this.user = this.route.snapshot.data.user;
      this.globalService.changePageTitle([this.user.email]);
    }

    this.profiles = this.route.snapshot.data.profileList;
    this.userProfiles = [];

    this.updateUserProfiles();

    this.constants = constants;
  }

  public saveUser() {
    // this.prepareUserProfiles();
    this.usersService.putUser(this.user).subscribe(
      result => {
        this.translate.get('USERS.USER_SAVED').subscribe((translation) => {
          this.alertsService.alertTrigger({ type: 'success', msg: translation });
        });
      },
      error => {
        console.log('Error', error);
      }
    );
  }

  public createUser() {
    this.user.id = 0;
    this.usersService.postUser(this.user).subscribe(
      result => {
        this.translate.get('USERS.USER_CREATED').subscribe((translation) => {
          this.alertsService.alertTrigger({ type: 'success', msg: translation });
        });
        this.router.navigate(['/users']);
      },
      error => {
        console.log('Error', error);
      }
    );
  }

  private updateUserProfiles() {
    for (const profile of this.user.profiles) {
      this.userProfiles.push(profile.id);
    }
  }

  prepareUserProfiles() {
    this.user.profiles = [];
    for (const id of this.userProfiles) {
      this.user.profiles.push(this.profiles.filter(prof => (prof.id === id))[0]);
    }
  }

  onSubmit() {
    if (this.isNew) {
      this.createUser();
    } else {
      this.saveUser();
    }
  }

  public avatarUrl() {
    if (this.tempAvatar) {
      return this.sanitizer.bypassSecurityTrustUrl(this.tempAvatar);
    } else {
      return constants.urlUserAvatar.replace(':id', this.user.id.toString()).replace(':file', this.user.avatar);
    }
  }

  // Aux function for comparison of combobox objects
  public compare(val1, val2) {
    if (val1 !== undefined && val2 !== undefined && val1 !== null && val2 !== null) {
      return val1.id === val2.id;
    } else {
      return false;
    }
  }

  matChipInputAdd(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      this.user.userAX.push(value.trim());
    }

    if (input) {
      input.value = '';
    }
  }

  matChipInputRemove(userAX: string): void {
    const index = this.user.userAX.indexOf(userAX);

    if (index >= 0) {
      this.user.userAX.splice(index, 1);
    }
  }

}
