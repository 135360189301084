import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/of';
import { DocumentManagerService } from '../services/document-manager.service';

@Injectable()
export class DMOrderListResolver implements Resolve<Observable<string>> {

    constructor(private documentManagerService: DocumentManagerService) { }

    resolve() {
        return this.documentManagerService.getOrders().catch(() => {
            return Observable.of('error');
        });
    }
}
