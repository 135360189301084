import { Component, OnInit, ViewChild } from '@angular/core';
import { ProjectForm, ProjectFormDictionary, ProjectFormInput, ProjectFormLayoutQuestion, ProjectFormLayout, ProjectFormQuestion } from '../../../interfaces/projectform';
import { ActivatedRoute } from '@angular/router';
import { LanguagesService } from '../../../services/languages.service';
import { constants } from '../../../../assets/constants';
import { FormsService } from '../../../services/forms.service';
import { FormManagerService } from '../../../services/formmanager.service';
import { TranslateService } from '@ngx-translate/core';
import { AlertsService } from '../../../services/alerts.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-form-manager-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class FormManagerEditComponent implements OnInit {

  form: ProjectFormLayout = {
    id: 0,
    creationDate: new Date().toISOString(),
    creationUser: new Date().toISOString(),
    key: '',
    name: '',
    questions: []
  };

  inputTypes: ProjectFormInput[];
  formList: ProjectFormLayout[];

  @ViewChild('editorForm', { static: true }) editorForm;

  widthList: number[] = [5, 10, 15, 20, 25, 30, 33, 35, 40, 45, 50, 55, 60, 65, 66, 70, 75, 80, 85, 90, 95, 100];

  private resourceLanguages: any[] = [
    {
      language: {
        id: 1,
        name: 'Spanish',
        cultureInfo: 'es-ES'
      },
      value: ''
    },
    {
      language: {
        id: 2,
        name: 'English',
        cultureInfo: 'en-GB'
      },
      value: ''
    },
    {
      language: {
        id: 3,
        name: 'Deutsch',
        cultureInfo: 'de-DE'
      },
      value: ''
    },
    {
      language: {
        id: 4,
        name: 'French',
        cultureInfo: 'fr-FR'
      },
      value: ''
    }
  ];

  elementCounter: number;
  currentLanguage: string;

  showHidden: boolean;

  isNew: boolean;

  constructor(private route: ActivatedRoute,
    public languagesService: LanguagesService,
    private formManagerService: FormManagerService,
    private translate: TranslateService,
    private alertsService: AlertsService) {

    this.elementCounter = 0;
    this.showHidden = false;
  }

  ngOnInit() {
    if (this.route.snapshot.data.form === 'new') {
      this.isNew = true;
    } else {
      this.form = this.route.snapshot.data.form;
    }
    this.inputTypes = this.route.snapshot.data.inputTypes;
    this.formList = this.route.snapshot.data.formList;
    this.sortQuestions();
    // this.fixMissingLanguages();
  }

  public printForm() {
    console.log(this.form);
  }

  public addElement() {
    const newElement: ProjectFormLayoutQuestion = {
      id: 0,
      options: [],
      active: true,
      creationDate: new Date().toISOString(),
      key: '',
      dictionary: {
        id: 0,
        key: '',
        resources: JSON.parse(JSON.stringify(this.resourceLanguages))
      },
      dictionarySubtitle: null,
      order: 1001,
      width: 100,
      input: {
        id: 1,
        name: 'Input Text',
        type: null,
        inputType: {
          id: 1,
          name: 'CODE'
        }
      }
    };
    this.form.questions.push(newElement);

    this.elementCounter++;
    this.scrollToBottom();
  }

  public changeType(question: ProjectFormLayoutQuestion) {
    if (question.input.id === 22 && question.options.length === 0) { // Auto-add one option field if relatedIds component
      this.addOption(question);
    }
  }

  public addOption(question: ProjectFormLayoutQuestion) {
    const newOption: any = {
      id: 0,
      key: '',
      dictionary: {
        id: 0,
        key: '',
        resources: JSON.parse(JSON.stringify(this.resourceLanguages))
      },
      value: question.options.length,
      order: question.options.length,
      active: true,
      creationDate: new Date().toISOString()
    };
    question.options.push(newOption);

  }

  private sortQuestions() {
    this.form.questions.sort((a, b) => {
      if (a.order < b.order) {
        return -1;
      }
      if (a.order > b.order) {
        return 1;
      }
      return 0;
    });
  }

  public disableOption(option: any) {
    option.active = false;
  }
  public enableOption(option: any) {
    option.active = true;
  }

  saveChanges() {
    if (this.isNew) {
      this.createFormLayout();
    } else {
      this.saveFormLayout();
    }
  }

  public createFormLayout() {
    this.formManagerService.postForm(this.form).subscribe(
      result => {
        this.translate.get('FORMS.FORM_LAYOUT_CREATED').subscribe((translation) => {
          this.alertsService.alertTrigger({ type: 'success', msg: translation, button: 'Ok' });
        });
        this.reloadForm();
      },
      error => {
        console.log('Error', error);
      }
    );
  }

  public saveFormLayout() {
    this.formManagerService.putForm(this.form).subscribe(
      result => {
        this.translate.get('FORMS.FORM_LAYOUT_SAVED').subscribe((translation) => {
          this.alertsService.alertTrigger({ type: 'success', msg: translation });
        });
        // this.reloadForm();
      },
      error => {
        console.log('Error', error);
      }
    );
  }

  private reloadForm() {
    this.formManagerService.getForm(this.form.id).subscribe(
      result => {
        this.form = result;
        this.sortQuestions();
      });
  }

  public copyFrom(a: number, b: number) {
    this.form.questions.forEach(q => {
      q.dictionary.resources[b].value = q.dictionary.resources[a].value;
      if (q.options.length > 0) {
        q.options.forEach(o => {
          o.dictionary.resources[b].value = o.dictionary.resources[a].value;
        });
      }
    });
  }

  public disableElement(question: ProjectFormLayoutQuestion) {
    // const index = this.form.questions.findIndex(x => x.id === question.id);
    // this.form.questions.splice(index, 1);
    question.active = false;
  }
  public enableElement(question: ProjectFormLayoutQuestion) {
    // const index = this.form.questions.findIndex(x => x.id === question.id);
    // this.form.questions.splice(index, 1);
    question.active = true;
  }

  public cloneElement(question: ProjectFormLayoutQuestion) {
    const index = this.form.questions.findIndex(x => x.id === question.id);
    this.form.questions.splice(index + 1, 0, JSON.parse(JSON.stringify(question)));
    this.form.questions[index + 1].id = 0;
    this.form.questions[index + 1].key = '';
    this.form.questions[index + 1].order = question.order + 1;
    this.form.questions[index + 1].dictionary.id = 0;
    this.form.questions[index + 1].dictionary.key = '';
    this.form.questions[index + 1].options.forEach(option => {
      option.id = 0;
      option.key = '';
      option.dictionary.id = 0;
      option.dictionary.key = '';
    });
  }

  public getInputTypes(type: number) {
    return this.inputTypes.filter(x => x.inputType.id === type).sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });
  }

  // Aux function for comparison of combobox objects
  public compare(val1, val2) {
    if (val1 !== undefined && val2 !== undefined && val1 !== null && val2 !== null) {
      return val1.id === val2.id;
    } else {
      return false;
    }
  }

  private scrollToBottom() {
    setTimeout(() => {
      this.editorForm.nativeElement.scrollTop = this.editorForm.nativeElement.scrollHeight;
    }, 50);
  }

  public showInput(input: ProjectFormInput): boolean {
    const inputHideIn = [11, 13];
    return !inputHideIn.some(i => i === input.id);
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.form.questions, event.previousIndex, event.currentIndex);
    this.form.questions.forEach((question, index) => {
      question.order = index + 1;
    });
  }

  randomId() {
    return Math.round((Math.random() * 100000));
  }

  // fixMissingLanguages() {
  //   this.form.questions.forEach(question => {
  //     this.resourceLanguages.forEach(resourceLanguage => {
  //       let found = false;
  //       question.dictionary.resources.forEach(resource => {
  //         if (resource.language.id === resourceLanguage.language.id) {
  //           found = true;
  //         }
  //       });
  //       if (!found) {
  //         question.dictionary.resources.push(resourceLanguage);
  //       }
  //     });
  //   });
  //   console.log(this.form.questions);
  // }


}
