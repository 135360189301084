import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { HttpClient } from '@angular/common/http';
import { constants } from '../../assets/constants';
import { ChatMessage } from '../interfaces/chatmessage';

@Injectable()
export class ChatService {

  private urlGetChat = constants.urlAPI + constants.endpoints.chat.get;
  private urlPostChat = constants.urlAPI + constants.endpoints.chat.post;

  constructor(public http: HttpClient) { }

  public getChat(id): Observable<any> {
    return this.http.get(this.urlGetChat.replace(':id', id));
  }

  public postChat(chatMessage: ChatMessage): Observable<any> {
    const data = chatMessage;
    return this.http.post(this.urlPostChat, data);
  }

}
