import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { User } from '../../interfaces/user';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { constants } from '../../../assets/constants';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { GlobalService } from '../../services/global.service';
import { TranslateService } from '@ngx-translate/core';
import { UsersService } from '../../services/users.service';
import { AlertsService } from '../../services/alerts.service';
import { ModalRemoveUserComponent } from './modal-remove-user.component';

@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.scss']
})
export class CustomersComponent implements OnInit, AfterViewInit {
  userList: any; // User[]

  canCreate: boolean;
  canModify: boolean;

  displayedColumns = ['email', 'name', 'creationDate', 'actions'];
  dataSource = new MatTableDataSource;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  constructor(private route: ActivatedRoute,
    private globalService: GlobalService,
    private translate: TranslateService,
    private usersService: UsersService,
    private alertsService: AlertsService,
    public dialog: MatDialog,
    public authService: AuthService) { }

  ngOnInit() {
    this.userList = this.route.snapshot.data.userList;

    this.translate.get('CUSTOMERS.TITLE').subscribe((translation) => {
      this.globalService.changePageTitle([translation]);
    });

    this.canModify = this.authService.isInRole(constants.roles.cj_user_modify);
    this.canCreate = this.authService.isInRole(constants.roles.cj_user_create);
  }

  ngAfterViewInit() {
    this.dataSource = new MatTableDataSource(this.userList);
    this.dataSource.paginator = this.paginator;
    this.dataSource.paginator.firstPage();
    this.dataSource.sort = this.sort;
  }

  public updateUserList() {
    this.usersService.getUsers().subscribe(
      result => {
        this.userList = result;
        this.dataSource = new MatTableDataSource(this.userList);
      }
    );
  }

  private modalRemoveUserOpen(user: User) {
    this.dialog.open(ModalRemoveUserComponent, {
      data: {
        user: user,
        users: this.userList
      }
    });
  }
}
