import { Component, OnInit, ViewChild, EventEmitter, AfterViewInit, Input, Output } from '@angular/core';
import { SignaturePad } from 'angular2-signaturepad/signature-pad';

@Component({
  selector: 'app-signature',
  templateUrl: './signature.component.html',
  styleUrls: ['./signature.component.scss']
})
export class SignatureComponent implements OnInit, AfterViewInit {

  @ViewChild(SignaturePad, { static: true }) signaturePad: SignaturePad;

  @Input() signatureData: string;
  @Input() enabled: boolean;

  @Output() saveSignature = new EventEmitter();

  public signaturePadOptions: Object = {
    'minWidth': 0.5,
    'maxWidth': 1.2,
    'canvasWidth': 300,
    'canvasHeight': 300,
    'penColor': '#000066'
  };

  constructor() { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    if (!this.enabled) {
      this.signaturePad.off();
    }
    this.signaturePad.resizeCanvas();
    this.signaturePad.clear();
    if (this.signatureData) {
      // this.signaturePad.fromData(JSON.parse(this.signatureData));
      this.signaturePad.fromDataURL(this.signatureData);
    }
  }

  drawStart() { }


  drawComplete() {
    // this.signatureData = JSON.stringify(this.signaturePad.toData());
    this.signatureData = this.signaturePad.toDataURL();
    this.saveSignature.emit(this.signatureData);
  }

}
