import { Component, OnInit, Input, ViewChild, TemplateRef, Inject } from '@angular/core';
import { Order, OrderDownload, OrderDownloadFile } from '../../../../interfaces/order';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormsService } from '../../../../services/forms.service';
import { constants } from '../../../../../assets/constants';
import { LoadingService } from '../../../../services/loading.service';
import { ProjectForm } from '../../../../interfaces/projectform';
import { TranslateService } from '@ngx-translate/core';
import { AlertsService } from '../../../../services/alerts.service';
import { FilesService } from '../../../../services/files.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-downloads',
  templateUrl: './downloads.component.html',
  styleUrls: ['./downloads.component.scss']
})
export class DownloadsComponent implements OnInit {

  @ViewChild('downloadsDialog', { static: true }) downloadsDialog: TemplateRef<any>;
  @ViewChild('uploadDialog', { static: true }) uploadDialog: TemplateRef<any>;
  @Input() order: Order;

  private mockupDownloads: OrderDownload[];
  private formData: ProjectForm;

  constructor(public dialog: MatDialog,
    private formsService: FormsService,
    private loadingService: LoadingService,
    private translate: TranslateService,
    private alertsService: AlertsService,
    private filesService: FilesService,
    private sanitizer: DomSanitizer) {
  }

  ngOnInit() {

  }

  private openDownloadsModal(download: OrderDownload) {
    this.dialog.open(this.downloadsDialog, {
      data: download,
      minWidth: '60vw',
      maxWidth: '100vw',
      maxHeight: '100vh',
      autoFocus: false
    });
  }

  private openUploadModal() {
    this.formsService.getProjectForm(this.order.project.id, constants.forms.FORM_ORDER_GALLERY_UPLOAD).subscribe(
      result => {
        this.formData = result;
        this.dialog.open(this.uploadDialog, {
          data: this.formData,
          minWidth: '40vw',
          maxWidth: '100vw',
          maxHeight: '100vh',
          autoFocus: false
        });
      },
      error => {
        console.log('Error');
      }
    );
  }

  public saveForm() {
    this.loadingService.showSpinner('Saving data');
    this.formsService.postForm(this.formData).subscribe(
      result => {
        this.loadingService.hideSpinner();
        this.translate.get('ORDERS.FILE_UPLOADED').subscribe((translation) => {
          this.alertsService.alertTrigger({ type: 'success', msg: translation, button: 'Ok' });
        });
        // this.reloadFormData();
        // this.reloadProject();
      },
      error => {
        this.loadingService.hideSpinner();
        this.translate.get('ORDERS.ERROR_UPLOADING_FILE').subscribe((translation) => {
          this.alertsService.alertTrigger({ type: 'error', msg: translation, button: 'Ok' });
        });
      }
    );
  }

  private openFile(file: OrderDownloadFile) {
    if (file.uniqueId !== null && file.uniqueId !== undefined) {
      this.filesService.getOrderFile(file.uniqueId).subscribe(
        result => {
          window.open(result.path, '_blank');
        },
        error => {
          console.log('Error');
        }
      );
    } else {
      let fileUrl;
      //fileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(file.path);
      fileUrl = file.path;
      window.open(fileUrl, '_blank');
    }
  }
}
