import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { HttpClient } from '@angular/common/http';
import { constants } from '../../assets/constants';
import { ProjectForm, ProjectFormValidation } from '../interfaces/projectform';

@Injectable()
export class FormsService {

  private urlGetForms = constants.urlAPI + constants.endpoints.forms.get;
  private urlGetForm = constants.urlAPI + constants.endpoints.form.get;
  private urlPostForm = constants.urlAPI + constants.endpoints.form.post;
  private urlPutForm = constants.urlAPI + constants.endpoints.form.put;
  private urlGetInputTypes = constants.urlAPI + constants.endpoints.form.getInputTypes;
  private urlGetProjectForm = constants.urlAPI + constants.endpoints.form.getProjectForm;
  private urlPostProjectForm = constants.urlAPI + constants.endpoints.form.postProjectForm;
  private urlValidateProjectForm = constants.urlAPI + constants.endpoints.form.validateProjectForm;
  private urlGetFormsAvailable = constants.urlAPI + constants.endpoints.form.getFormsAvailable;

  constructor(public http: HttpClient) { }

  public getForms(): Observable<any> {
    return this.http.get(this.urlGetForms);
  }

  public getForm(id): Observable<any> {
    return this.http.get(this.urlGetForm.replace(':id', id.toString()));
  }

  public putForm(form: ProjectForm): Observable<any> {
    const data = form;
    return this.http.put(this.urlPutForm, data);
  }

  public postForm(form: ProjectForm): Observable<any> {
    const data = form;
    return this.http.post(this.urlPostProjectForm, data);
  }

  public getInputTypes(): Observable<any> {
    return this.http.get(this.urlGetInputTypes);
  }

  public getProjectForm(id, fid): Observable<any> {
    return this.http.get(this.urlGetProjectForm.replace(':id', id.toString()).replace(':fid', fid.toString()));
  }

  public validateForm(data: ProjectFormValidation): Observable<any> {
    return this.http.post(this.urlValidateProjectForm, data);
  }

  public getFormsAvailable(id): Observable<any> {
    return this.http.get(this.urlGetFormsAvailable.replace(':id', id.toString()));
  }

}
