import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { UsersService } from '../services/users.service';
import { ActivatedRouteSnapshot } from '@angular/router';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/of';

@Injectable()
export class UserSettingsResolver implements Resolve<Observable<string>> {

  constructor(private usersService: UsersService) { }

  resolve(route: ActivatedRouteSnapshot) {
    return this.usersService.getUser(JSON.parse(localStorage.getItem('pmsession')).userId).catch(() => {
      return Observable.of('error');
    });

  }
}
