import { Component, OnInit, ViewChild, Renderer2, AfterViewInit, ElementRef, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { constants } from '../../../../assets/constants';
import { ProjectForm, ProjectFormQuestion, ProjectFormValidation } from '../../../interfaces/projectform';
import { ActivatedRoute, Router } from '@angular/router';
import { LanguagesService } from '../../../services/languages.service';
import { AuthService } from '../../../services/auth.service';
import { Project } from '../../../interfaces/project';
import { InputType } from '../../../interfaces/inputType';
import { FormsService } from '../../../services/forms.service';
import { GlobalService } from '../../../services/global.service';
import { TranslateService } from '@ngx-translate/core';
import { AlertsService } from '../../../services/alerts.service';
import { LogMessage } from '../../../interfaces/logmessage';
import { UserNotification } from '../../../interfaces/notification';
import { LoadingService } from '../../../services/loading.service';

import * as jspdf from 'jspdf';
import * as html2canvas from 'html2canvas';
import { MediaMatcher } from '@angular/cdk/layout';
import { MatDialog } from '@angular/material/dialog';
import { ModalValidateFormComponent } from './modal-validate-form.component';
import { EventsService } from '../../../services/events.service';
import { ProjectsService } from '../../../services/projects.service';
import { ModalViewFormComponent } from '../../partials/file-view/modal-view-form.component';

@Component({
  selector: 'app-form-view',
  templateUrl: './form-view.component.html',
  styleUrls: ['./form-view.component.scss']
})
export class FormViewComponent implements OnInit, AfterViewInit, OnDestroy {

  public project: Project;
  // public form: ProjectForm;
  public formData: ProjectForm;
  public inputTypes: InputType[];
  public logMessages: LogMessage[];
  public notificationList: UserNotification[];

  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;

  // formLayout
  // @ViewChild('formCont') formCont;
  @ViewChild('inputText', { static: false }) inputText;

  @ViewChild('content', { static: false }) content: ElementRef;

  timelineMenuVisible: boolean;
  messagesVisible: boolean;
  ganttPanelOpened: boolean;
  validationComment: string;

  constructor(private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService,
    private globalService: GlobalService,
    public languagesService: LanguagesService,
    public authService: AuthService,
    public formsService: FormsService,
    public loadingService: LoadingService,
    private alertsService: AlertsService,
    private eventsService: EventsService,
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    private projectsService: ProjectsService,
    public dialog: MatDialog) {

    // this.form = this.route.snapshot.data.projectForm;
    this.formData = this.route.snapshot.data.projectFormData;
    this.project = this.route.snapshot.data.projectInfo;
    this.inputTypes = this.route.snapshot.data.inputTypes;
    this.logMessages = this.route.snapshot.data.logMessages;
    this.notificationList = this.route.snapshot.data.notifications;

    // this is for routerLink on same component when only the parameter changes
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };

    if (localStorage.getItem('ganttPanel')) {
      this.ganttPanelOpened = JSON.parse(localStorage.getItem('ganttPanel'));
    } else {
      this.ganttPanelOpened = true;
      localStorage.setItem('ganttPanel', this.ganttPanelOpened.toString());
    }

    // this.formData.questions.forEach(question => {
    //   console.log('form', this.formData);
    //   const formQuestion = this.form.questions.find(x => x.id === question.id);
    //   console.log('formQuestion', this.form);
    //   formQuestion.value = question.value[0];
    // });

    this.mobileQuery = media.matchMedia('(max-width: 800px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);

    this.timelineMenuVisible = !this.mobileQuery.matches;

    this.globalService.setProjectId(this.project.id);
  }

  ngOnInit() {
    this.formData.questions.forEach(question => {
      switch (this.getType(question.input.id)) {
        case 'input_text': this.formCreateInputText(question);
      }
    });
    this.sortQuestions();
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
    this.globalService.setProjectId(0);
  }

  ngAfterViewInit() {
    this.globalService.changePageTitle([this.project.name + ' - ' + this.formData.name]);
    this.eventsService.doEvent({ key: 'newNotifications', value: 'true' });
  }

  private formCreateInputText(question: ProjectFormQuestion) {

  }

  public getId(type: string) {
    return this.inputTypes.find(x => x.type === type).id;
  }
  public getType(id: number) {
    return this.inputTypes.find(x => x.id === id).type;
  }

  public saveFormDraft() {
    this.loadingService.showSpinner('Saving data');
    this.formsService.postForm(this.formData).subscribe(
      result => {
        this.loadingService.hideSpinner();
        this.translate.get('FORMS.DRAFT_SAVED').subscribe((translation) => {
          this.alertsService.alertTrigger({ type: 'success', msg: translation, button: 'Ok' });
        });
        this.reloadFormData();
        this.reloadProject();
      },
      error => {
        this.loadingService.hideSpinner();
        this.translate.get('FORMS.ERROR_SAVING_DRAFT').subscribe((translation) => {
          this.alertsService.alertTrigger({ type: 'error', msg: translation, button: 'Ok' });
        });
      }
    );
  }

  public reloadFormData() {
    this.formsService.getProjectForm(this.project.id, this.formData.id).subscribe(
      result => {
        Object.assign(this.formData, result);
      },
      error => {
        console.log('Error', error);
      }
    );
  }
  public reloadProject() {
    this.projectsService.getProject(this.project.id).subscribe(
      result => {
        Object.assign(this.project, result);
        this.eventsService.doEvent({ key: 'gantt', value: 'reload' });
        this.eventsService.doEvent({ key: 'timeline', value: {action: 'reload', project: this.project} });
      },
      error => {
        console.log('Error', error);
      }
    );
  }

  public validateForm(action: string, comments?: string) {
    this.loadingService.showSpinner('Validating form');
    if (action === 'REJECT' || action === 'VALIDATE') {
      let validate;
      if (action === 'REJECT') {
        validate = false;
      }
      if (action === 'VALIDATE') {
        validate = true;
      }

      const data: ProjectFormValidation = {
        form: this.formData,
        comments: comments === undefined ? '' : comments,
        validate: validate
      };

      this.formsService.validateForm(data).subscribe(
        result => {
          this.loadingService.hideSpinner();
          if (action === 'REJECT') {
            this.formData.proposed = false;
            this.formData.validated = false;
            this.translate.get('FORMS.DATA_REJECTED').subscribe((translation) => {
              this.alertsService.alertTrigger({ type: 'success', msg: translation, button: 'Ok' });
            });
            this.reloadProject();
          }
          if (action === 'VALIDATE') {
            this.formData.validated = true;
            this.translate.get('FORMS.DATA_VALIDATED').subscribe((translation) => {
              this.alertsService.alertTrigger({ type: 'success', msg: translation, button: 'Ok' });
            });
            this.reloadProject();
          }
        },
        error => {
          this.loadingService.hideSpinner();
          this.translate.get('FORMS.ERROR_VALIDATING').subscribe((translation) => {
            this.alertsService.alertTrigger({ type: 'error', msg: translation, button: 'Ok' });
          });
        }
      );
    }
  }

  onSubmit() {
  }

  private sortQuestions() {
    this.formData.questions.sort((a, b) => {
      if (a.order < b.order) {
        return -1;
      }
      if (a.order > b.order) {
        return 1;
      }
      return 0;
    });
  }

  public downloadPDF() {

    const data = document.getElementById('content');
    html2canvas(data).then(canvas => {
      // Few necessary setting options
      const imgWidth = 208;
      const pageHeight = 295;
      const imgHeight = canvas.height * imgWidth / canvas.width;
      const heightLeft = imgHeight;

      const contentDataURL = canvas.toDataURL('image/png');
      const pdf = new jspdf('p', 'mm', 'a4'); // A4 size page of PDF
      const position = 0;
      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
      pdf.save('pdf.pdf'); // Generated PDF
    });

  }

  public print() {
    window.print();
  }
  public modalViewPdf() {
      this.dialog.open(ModalViewFormComponent, {
        data: {
          project: this.project.id,
          form: this.formData.id
        },
        width: '90%',
        height: '95%'
      });
  }

  canShowButton(name: string) {
    if (this.formData.methods !== undefined && this.formData.methods.find(x => x.name === name && this.authService.checkRoleIds(x.roles))) {
      return true;
    }
  }

  public toggleGanttPanel() {
    this.ganttPanelOpened = !this.ganttPanelOpened;
    localStorage.setItem('ganttPanel', this.ganttPanelOpened.toString());
  }

  public toggleTimelineMenu() {
    this.messagesVisible = false;
    this.timelineMenuVisible = !this.timelineMenuVisible;
  }
  public toggleMessages() {
    this.timelineMenuVisible = false;
    this.messagesVisible = !this.messagesVisible;
  }

  private modalValidationOpen(action: string) {
    const dialogRef = this.dialog.open(ModalValidateFormComponent, {
      data: {
        action: action
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.validateForm(result.action, result.comments);
    });

  }
}
