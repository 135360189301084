import { Component, OnInit } from '@angular/core';
import { ProjectDayBlock, GanttTable, GanttTableElement } from '../../../interfaces/project';
import { ActivatedRoute } from '@angular/router';
import { EventsService } from '../../../services/events.service';

@Component({
  selector: 'app-gantt',
  templateUrl: './gantt.component.html',
  styleUrls: ['./gantt.component.scss']
})
export class GanttComponent implements OnInit {

  projectDays: ProjectDayBlock[];

  projectDaysData: GanttTableElement[];

  constructor(private route: ActivatedRoute, private events: EventsService) {
  }

  ngOnInit() {
    this.projectDaysData = this.route.snapshot.data.projectInfo.ganttTable;
    this.createProjectDays();

    this.events.event$.subscribe((params: any) => {
      if (params.key === 'gantt') {
        if (params.value === 'reload') {
          this.projectDaysData = this.route.snapshot.data.projectInfo.ganttTable;
          this.createProjectDays();
        }
      }
    });
  }

  createProjectDays() {
    this.projectDays = [];

    let minDate = new Date();
    let maxDate = new Date();

    let date = new Date();
    if (this.projectDaysData) {
      this.projectDaysData.forEach(element => {
        date = element.proposedStartDate ? new Date(element.proposedStartDate) : date;
        date = element.actualStartDate ? new Date(element.actualStartDate) : date;
        date = element.proposedEndDate ? new Date(element.proposedEndDate) : date;
        date = element.actualEndDate ? new Date(element.actualEndDate) : date;

        minDate = date < minDate ? date : minDate;
        maxDate = date > maxDate ? date : maxDate;
      });

      const currentDate = minDate;
      currentDate.setDate(1);
      currentDate.setHours(0);
      currentDate.setMinutes(0);
      currentDate.setSeconds(0);

      const endDate = maxDate;
      endDate.setDate(this.daysInMonth(endDate.getMonth() + 1, endDate.getFullYear()));
      endDate.setHours(23);
      endDate.setMinutes(59);
      endDate.setSeconds(59);

      while (currentDate <= endDate) {
        const newDay: ProjectDayBlock = {
          day: currentDate.getDate(),
          month: currentDate.getMonth(),
          year: currentDate.getFullYear(),
        };
        this.projectDays.push(newDay);
        currentDate.setDate(currentDate.getDate() + 1);
      }
    }
  }

  private daysInMonth(month, year) {
    return new Date(year, month, 0).getDate();
  }

  public showMonthTitle(dayBlock: ProjectDayBlock) {
    const index = this.projectDays.indexOf(dayBlock);
    if (index === 0) {
      return true;
    } else {
      if (this.projectDays[index].month !== this.projectDays[index - 1].month) {
        return true;
      } else {
        return false;
      }
    }
  }

  public monthName(month: number) {
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    return months[month];
  }


  public isDaySelected(element: GanttTableElement, dayBlock: ProjectDayBlock, dateProperty: string) {
    const elementDate = new Date(element[dateProperty]);
    const dayBlockDate = new Date(dayBlock.year, dayBlock.month, dayBlock.day, 0, 0, 0);

    if (dayBlockDate.getDate() === elementDate.getDate()
      && dayBlockDate.getMonth() === elementDate.getMonth()
      && dayBlockDate.getFullYear() === elementDate.getFullYear()) {
      return true;
    } else {
      return false;
    }
  }

  public rangeDaySelected(element: GanttTableElement, dayBlock: ProjectDayBlock) {
    const elementStartDate = new Date(element.actualStartDate);
    elementStartDate.setHours(0, 0, 0);
    const elementEndDate = new Date(element.actualEndDate);
    elementEndDate.setHours(23, 59, 59);
    const dayBlockDate = new Date(dayBlock.year, dayBlock.month, dayBlock.day);

    return dayBlockDate >= elementStartDate && dayBlockDate <= elementEndDate;
  }

  public isWeekend(dayBlock: ProjectDayBlock) {
    const dayBlockDate = new Date(dayBlock.year, dayBlock.month, dayBlock.day);
    return dayBlockDate.getDay() === 0 || dayBlockDate.getDay() === 6;
  }

  public isToday(dayBlock: ProjectDayBlock) {
    const dayBlockDate = new Date(dayBlock.year, dayBlock.month, dayBlock.day);
    const today = new Date();
    today.setHours(0, 0, 0);

    return dayBlockDate.getDate() === today.getDate()
      && dayBlockDate.getMonth() === today.getMonth()
      && dayBlockDate.getFullYear() === today.getFullYear();
  }

  public isDelay(element: GanttTableElement, dayBlock: ProjectDayBlock, dateProperty1: string, dateProperty2: string, ) {
    const elementDate1 = new Date(element[dateProperty1]);
    elementDate1.setHours(0, 0, 0);
    const elementDate2 = new Date(element[dateProperty2]);
    elementDate2.setHours(0, 0, 0);

    const dayBlockDate = new Date(dayBlock.year, dayBlock.month, dayBlock.day);

    return dayBlockDate > elementDate1 && dayBlockDate <= elementDate2;
  }

}
