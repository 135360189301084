import { Component, OnInit, Input } from '@angular/core';
import { ModalViewFileComponent } from './modal-view-file.component';
import { MatDialog } from '@angular/material/dialog';
import { ProjectFile } from '../../../interfaces/projectfile';
import { ModalRemoveFileComponent } from './modal-remove-file.component';
import { ModalDownloadFileComponent } from './modal-download-file.component';


@Component({
  selector: 'app-file-view',
  templateUrl: './file-view.component.html',
  styleUrls: ['./file-view.component.scss']
})
export class FileViewComponent implements OnInit {

  @Input() files: ProjectFile[];
  @Input() canDelete?: boolean;

  constructor(public dialog: MatDialog) { }

  ngOnInit() {
  }

  private modalViewFile(file: ProjectFile) {
    this.dialog.open(ModalViewFileComponent, {
      data: {
        file: file
      },
      maxWidth: '90%',
      maxHeight: '95%',
      minWidth: '20%',
      width: '85%'
    });
  }

  private modalDownloadFile(file: ProjectFile) {
    this.dialog.open(ModalDownloadFileComponent, {
      data: {
        file: file
      },
      width: '50%'
    });
  }

  private modalRemoveFileOpen(file: ProjectFile) {
    this.dialog.open(ModalRemoveFileComponent, {
      data: {
        file: file,
        files: this.files
      }
    });
  }

  private wrapText(text: string) {
    const maxChars = 20;
    return text.length > maxChars ? text.substr(0, maxChars) + ' ...' : text;
  }
}
