import { Component, OnInit } from '@angular/core';
import { User } from '../../interfaces/user';
import { ActivatedRoute } from '@angular/router';
import { GlobalService } from '../../services/global.service';
import { UsersService } from '../../services/users.service';
import { AlertsService } from '../../services/alerts.service';
import { TranslateService } from '@ngx-translate/core';
import { constants } from '../../../assets/constants';
import { DomSanitizer } from '@angular/platform-browser';
import { LanguagesService } from '../../services/languages.service';

@Component({
  selector: 'app-user-settings',
  templateUrl: './user-settings.component.html',
  styleUrls: ['./user-settings.component.scss']
})
export class UserSettingsComponent implements OnInit {

  constants;
  avatarPath: string;
  tempAvatar: string;

  user: User = {
    id: null,
    email: '',
    password: '',
    status: null,
    recoveryHash: '',
    creationDate: '',
    birthDate: '',
    refreshToken: '',
    refreshTokenExpires: '',
    name: '',
    lastName: '',
    country: '',
    experience: 0,
    gender: '',
    address: '',
    zip: '',
    city: '',
    allowNewsletter: true,
    allowNotifications: true,
    phone: '',
    profiles: [],
    avatar: '',
    language: constants.languages[1],
  };

  constructor(private route: ActivatedRoute,
    private usersService: UsersService,
    private alertsService: AlertsService,
    private translate: TranslateService,
    public globalService: GlobalService,
    public sanitizer: DomSanitizer,
    public languagesService: LanguagesService) { }

  ngOnInit() {
    this.user = this.route.snapshot.data.user;
    this.globalService.changePageTitle([this.user.email]);
    this.constants = constants;
  }


  public saveUser() {
    this.usersService.putUser(this.user).subscribe(
      result => {
        this.translate.get('USERS.USER_SAVED').subscribe((translation) => {
          this.alertsService.alertTrigger({ type: 'success', msg: translation });
        });
        this.languagesService.setLanguage(this.user.language.id);
      },
      error => {
        console.log('Error', error);
      }
    );
  }

  onSubmit() {
    this.saveUser();
  }

  public avatarUrl() {
    if (this.tempAvatar) {
      return this.sanitizer.bypassSecurityTrustUrl(this.tempAvatar);
    } else {
      return constants.urlUserAvatar.replace(':id', this.user.id.toString()).replace(':file', this.user.avatar);
    }
  }

  // Aux function for comparison of combobox objects
  public compare(val1, val2) {
    if (val1 !== undefined && val2 !== undefined && val1 !== null && val2 !== null) {
      return val1.id === val2.id;
    } else {
      return false;
    }
  }

  async postFile(data: File): Promise<any> {
    this.globalService.postFile(data).subscribe(
      fileName => {
        this.avatarPath = constants.urlAPP + constants.urlTempFiles;
        this.user.avatar = fileName;
        this.tempAvatar = URL.createObjectURL(data);
        console.log(this.user);
        console.log(this.tempAvatar);
      }, error => {
        this.translate.get('USER_DETAIL.ERROR_UPLOAD_AVATAR').subscribe((translation) => {
          this.alertsService.alertTrigger({ msg: translation, button: 'Ok' });
        });
      }
    );
  }
}
