import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../../../services/auth.service';
import { ProjectMini } from '../../../interfaces/project';
import { GlobalService } from '../../../services/global.service';
import { TutorialService } from '../../../services/tutorial.service';

@Component({
  selector: 'app-project-list',
  templateUrl: './project-list.component.html',
  styleUrls: ['./project-list.component.scss']
})
export class ProjectListComponent implements OnInit, AfterViewInit {

  projectList: ProjectMini[];

  displayedColumns = ['id', 'company', 'order', 'name', 'actions', 'notifications'];
  dataSource = new MatTableDataSource;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  constructor(private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService,
    private globalService: GlobalService,
    public authService: AuthService,
    private tutorialService: TutorialService) {
  }

  ngOnInit() {
    this.projectList = this.route.snapshot.data.projectList;

    this.translate.get('PROJECTS.TITLE').subscribe((translation) => {
      this.globalService.changePageTitle([translation]);
    });

    // Si solo hay un proyecto, redirigir a el. Si no, mostrar modal de tutorial
    //    if (this.projectList.length > 1) {

    this.tutorialService.modalTutorial(
      {
        tutoId: 'pj-welcome',
        title: 'TUTORIAL.PROJECT_WELCOME_TITLE',
        icon: 'work',
        content: 'TUTORIAL.PROJECT_WELCOME_CONTENT'
      });
    //    } else {
    //      this.router.navigate(['/project/' + this.projectList[0].id]);
    //    }

  }

  ngAfterViewInit() {
    this.dataSource = new MatTableDataSource(this.projectList);
    this.dataSource.paginator = this.paginator;
    this.dataSource.paginator.firstPage();
    this.dataSource.sort = this.sort;
  }

}
