import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ModalValidateFormComponent } from '../../project/form-view/modal-validate-form.component';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-tutorial',
  templateUrl: './tutorial.component.html',
  styleUrls: ['./tutorial.component.scss']
})
export class TutorialModalComponent implements OnInit {

  public tutorialOverride: string[];
  public dontShowAgain: boolean;
  public okButtonText: number;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ModalValidateFormComponent>,
    private router: Router) {
  }

  ngOnInit() {
    this.okButtonText = Math.floor(Math.random() * 6 + 1);
  }

  public modalClose() {
    if (this.dontShowAgain) {
      this.tutorialOverride = JSON.parse(localStorage.getItem('tutorialOverride'));
      if (this.tutorialOverride === null) {
        this.tutorialOverride = [];
      }
      this.tutorialOverride.push(this.data.tutoId);
      localStorage.setItem('tutorialOverride', JSON.stringify(this.tutorialOverride));
    }
    this.dialogRef.close();
    if (this.data.redirectTo !== undefined) {
      this.router.navigate([this.data.redirectTo]);
    }
  }
}
