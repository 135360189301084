import { Component, OnInit, TemplateRef, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { GlobalService } from '../../../services/global.service';
import { Profile } from '../../../interfaces/profile';
import { TranslateService } from '@ngx-translate/core';
import { AlertsService } from '../../../services/alerts.service';
import { ProjectFile } from '../../../interfaces/projectfile';
import { FilesService } from '../../../services/files.service';

@Component({
    selector: 'app-modal-remove-file',
    templateUrl: 'modal-remove-file.html',
})
export class ModalRemoveFileComponent {

    file: ProjectFile;
    files: ProjectFile[];

    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<ModalRemoveFileComponent>,
        private globalService: GlobalService,
        private filesService: FilesService,
        private router: Router,
        private translate: TranslateService,
        private alertsService: AlertsService) {

        this.file = data.file;
        this.files = data.files;
    }

    removeFile(file: ProjectFile) {
        this.deleteFile(file);
        this.dialogRef.close();
    }

    modalRemoveFileCancel() {
        this.dialogRef.close();
    }

    public deleteFile(fileToDelete: ProjectFile) {

        this.filesService.deleteFiles([fileToDelete.uniqueId]).subscribe(
            result => {
                this.translate.get('GALLERY.FILE_DELETED').subscribe((translation) => {
                    this.alertsService.alertTrigger({ type: 'success', msg: translation });
                });
                const index = this.files.findIndex(x => x.id === fileToDelete.id);
                this.files.splice(index, 1);
            },
            error => {
                console.log('Error', error);
            }
        );
    }
}

