import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { ActivatedRouteSnapshot } from '@angular/router';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/of';
import { FormsService } from '../services/forms.service';

@Injectable()
export class FormsAvailableResolver implements Resolve<Observable<string>> {

  constructor(private formsService: FormsService) { }

  resolve(route: ActivatedRouteSnapshot) {
    return this.formsService.getFormsAvailable(route.paramMap.get('id') === 'new' ? '0' : route.paramMap.get('id')).catch(() => {
      return Observable.of('error');
    });
  }
}
