import { Component, OnInit, ViewChild, Input, Output, EventEmitter, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { ProjectForm, ProjectFormQuestion, ProjectFormLayoutQuestion, ProjectFormQuestionOption } from '../../../interfaces/projectform';
import { ActivatedRoute } from '@angular/router';
import { LanguagesService } from '../../../services/languages.service';
import { constants } from '../../../../assets/constants';
import { FilesService } from '../../../services/files.service';
import { TranslateService } from '@ngx-translate/core';
import { AlertsService } from '../../../services/alerts.service';
import { GlobalService } from '../../../services/global.service';
import { NotificationsService } from '../../../services/notifications.service';
import { UserNotification } from '../../../interfaces/notification';
import { Project } from '../../../interfaces/project';
import { AuthService } from '../../../services/auth.service';
import { MatDialog } from '@angular/material/dialog';

import { ControlContainer, NgForm } from '@angular/forms';
import { ModalViewFileComponent } from '../file-view/modal-view-file.component';
import { MediaMatcher } from '@angular/cdk/layout';

@Component({
  selector: 'app-form-manager-view',
  templateUrl: './form-view.component.html',
  styleUrls: ['./form-view.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class FormManagerViewComponent implements OnInit, AfterViewInit {

  // formLayout
  @ViewChild('formCont', { static: false }) formCont;
  @ViewChild('inputText', { static: false }) inputText;

  // @Input() projectForm: ProjectForm;
  @Input() projectFormData: ProjectForm;
  @Input() notifications: UserNotification[];

  @Output() saveFormEvent = new EventEmitter();

  // form: ProjectForm;
  formData: ProjectForm;
  projectInfo: Project;
  // notifications: UserNotification[];

  linkComponentsInitialized: boolean;

  isTemplate: boolean;

  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;

  constructor(private route: ActivatedRoute,
    private filesService: FilesService,
    private translate: TranslateService,
    private alertsService: AlertsService,
    public languagesService: LanguagesService,
    public notificationsService: NotificationsService,
    public authService: AuthService,
    public global: GlobalService,
    public dialog: MatDialog,
    changeDetectorRef: ChangeDetectorRef, media: MediaMatcher) {

    this.projectInfo = this.route.snapshot.data.projectInfo;
    // this.form = this.route.snapshot.data.projectForm;
    this.linkComponentsInitialized = false;

    this.mobileQuery = media.matchMedia('(max-width: 800px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);

  }

  ngOnInit() {
    // this.form = this.projectForm;
    this.formData = this.projectFormData;
    // this.notifications = this.notificationList;

    this.notificationsService.manageNotifications(this.notifications, this.formData.id);
    this.isTemplate = this.formData.project === undefined;
  }

  ngAfterViewInit() {
    // const link = document.getElementsByClassName('link-component');
    // link[0].addEventListener('click', () => this.modalViewFile('http://www.google.es'), false);

    // this.generateAllLinkComponents();
  }

  public getId(type: string) {
    return constants.inputTypes.find(x => x.type === type).id;
  }
  public getType(id: number) {
    return constants.inputTypes.find(x => x.id === id).type;
  }

  public getValue(question: ProjectFormQuestion, i?: number, j?: number) {
    if (this.formData && !this.isTemplate) { // !== undefined, comprobamos que el formData viene de un formulario real y no un template
      const value = this.formData.questions.find(x => x.id === question.id);
      if (value === undefined) {
        return [''];
      } else {
        if (value.value.length === 0) {
          return [''];
        } else {
          if (i !== undefined && j !== undefined) {
            if (value.value !== undefined && value.value[i] !== undefined) {
              return value.value[i][j] !== undefined ? value.value[i][j] : '';
            } else {
              return '';
            }
          } else {
            return value.value;
          }
        }
      }
    } else {
      return [''];
    }
  }

  public getFiles(question: ProjectFormQuestion) {
    if (this.formData) {
      const value = this.formData.questions.find(x => x.id === question.id);
      if (value === undefined) {
        return [];
      } else {
        if (value.value && value.value.length === 0) {
          return value.value;
        } else {
          return value.value;
        }
      }
    } else {
      return [];
    }
  }

  public changeValue($event, question: ProjectFormQuestion) {
    const questionToChange = this.formData.questions.find(x => x.id === question.id);
    if (questionToChange) {
      if (question.input.type.id === 3) {
        questionToChange.value[0] = this.global.fixUTC(new Date($event.currentTarget !== undefined ? $event.currentTarget.value : $event.target.value)).toISOString();
      } else {
        questionToChange.value[0] = $event.currentTarget.value;
      }
    } else {
      const newQuestion = this.createQuestion(question, [$event.currentTarget !== undefined ? $event.currentTarget.value : $event.target.value]);

      if (newQuestion.input.type.id === 3) {
        newQuestion.value[0] = this.global.fixUTC(new Date(newQuestion.value[0])).toISOString();
      }

      this.formData.questions.push(newQuestion);
    }
  }

  public changeDynamicValue($event, question: ProjectFormQuestion, i: number, j: number) {
    const questionToChange = this.formData.questions.find(x => x.id === question.id);
    if (!questionToChange) {
      const newQuestion = this.createQuestion(question, [$event.currentTarget !== undefined ? $event.currentTarget.value : $event.target.value]);
      this.formData.questions.push(newQuestion);
    }
    if (questionToChange.value.length === 0) {
      questionToChange.value = [[]];
    }
    questionToChange.value[i][j] = $event.currentTarget.value;
  }

  public changeCalendarValue($event, question: ProjectFormQuestion) {
    const questionToChange = this.formData.questions.find(x => x.id === question.id);
    if (questionToChange) {
      questionToChange.value[0] = this.global.fixUTC(new Date($event)).toISOString();
    } else {
      const newQuestion = this.createQuestion(question, [$event]);

      newQuestion.value[0] = this.global.fixUTC(new Date(newQuestion.value[0])).toISOString();
      this.formData.questions.push(newQuestion);
    }
  }

  public changeSignature(signatureData, question: ProjectFormQuestion) {
    const questionToChange = this.formData.questions.find(x => x.id === question.id);
    if (questionToChange) {
      questionToChange.value[0] = signatureData;
    } else {
      const newQuestion = this.createQuestion(question, [signatureData]);
      this.formData.questions.push(newQuestion);
    }
  }

  public changeRadioSelection(option: ProjectFormQuestionOption, question: ProjectFormQuestion) {
    const questionToChange = this.formData.questions.find(x => x.id === question.id);
    if (questionToChange) {
      questionToChange.value = [option.value];
    } else {
      const newQuestion = this.createQuestion(question, [option.value]);
      this.formData.questions.push(newQuestion);
    }
  }
  public changeCheckboxSelection(option: ProjectFormQuestionOption, question: ProjectFormQuestion) {
    // const questionToChange: ProjectFormQuestion = this.formData.questions.find(x => x.id === question.id);
    // if (questionToChange) {
    //   //if (questionToChange.value.indexOf(String(option.value)) > -1) {
    //   if (questionToChange.value.includes(String(option.value))) {
    //     questionToChange.value.splice(questionToChange.value.indexOf(option.value), 1);
    //   } else {
    //     questionToChange.value.push(option.value);
    //   }
    // } else {
    //   const newQuestion = this.createQuestion(question, [option.value]);
    //   this.formData.questions.push(newQuestion);
    // }
    // console.log(question.value);
    //const questionToChange: ProjectFormQuestion = this.formData.questions.find(x => x.id === question.id);

    //if (questionToChange.value.indexOf(String(option.value)) > -1) {
    if (question.value.includes(option.value) || question.value.includes(String(option.value))) {
      question.value.splice(question.value.indexOf(option.value), 1);
    } else {
      question.value.push(option.value);
    }

    console.log(question.value);
  }

  private createQuestion(question: ProjectFormQuestion, questionValue: any) {
    const newQuestion: ProjectFormQuestion = {
      id: question.id,
      active: question.active,
      creationDate: question.creationDate,
      input: question.input,
      name: '',
      options: question.options,
      order: question.order,
      files: [],
      historic: [],
      subtitle: '',
      validated: false,
      value: questionValue,
      width: question.width,
      required: false,
      formQuestionId: question.formQuestionId
    };
    return newQuestion;
  }

  public isCheckedOption(question: ProjectFormQuestion, option: ProjectFormQuestionOption) {
    if (question.value !== undefined) {
      return question.value.includes(option.value) || question.value.includes(String(option.value));
    } else {
      return false;
    }
  }

  public extractDate(date: string) {
    if (date !== undefined) {
      return date.substr(0, 10);
    } else {
      return '';
    }
  }

  async postFile(data: File[]): Promise<any> {
    this.filesService.postFile(data).subscribe(
      fileName => {
        console.log(fileName);
      }, error => {
        this.translate.get('ERROR').subscribe((translation) => {
          this.alertsService.alertTrigger({ msg: translation, button: 'Ok' });
        });
      }
    );
  }

  public saveForm() {
    this.saveFormEvent.emit();
  }

  public canUserDo(action: string) {
    if (this.formData && this.formData.methods) {
      const method = this.formData.methods.find(x => (x.name === action));
      return method !== undefined && this.authService.checkRoleIds(method.roles);
    } else {
      return true;
    }
  }

  public getColumnWidth(elements: any) {
    return 100 / elements.length;
  }

  public addDynamicRow(question: ProjectFormQuestion) {
    let value = this.getValue(question);
    if (value === undefined) {
      value = [];
    }
    const newRow = [];
    question.options.forEach(x => {
      newRow.push('');
    });
    // value.push(newRow);

    let foundQuestion = this.formData.questions.find(x => (x.id === question.id));
    if (foundQuestion === undefined) {
      this.formData.questions.push(this.createQuestion(question, []));
    }

    foundQuestion = this.formData.questions.find(x => (x.id === question.id));
    foundQuestion.value.push(newRow);
  }

  public deleteDynamicRow(question: ProjectFormQuestion, row: number) {
    const rowIndex = this.formData.questions.findIndex(x => (x.id === question.id));
    this.formData.questions[rowIndex].value.splice(row, 1);
  }

  // public replaceLinksInText(text: string, options: any[]) {
  //   // Replaces '[1]link[/1]' with '<a href="option[0]>link</a>"
  //   const linksArray = text.match(/(\[[1-9]\])(.+?)(\[\/[1-9]\])/g);
  //   linksArray.forEach(link => {
  //     const id = Number(link.match(/(\[([1-9])\])(.+?)(\[\/[1-9]\])/)[2]);
  //     const linkText = link.match(/(\[([1-9])\])(.+?)(\[\/[1-9]\])/)[3];
  //     text = text.replace(link, '<span class="link-component">' + linkText + '</span>');
  //   });

  //   if (!this.linkComponentsInitialized) {
  //     const linkComponent = document.getElementsByClassName('link-component');
  //     for (let i = 0; i < linkComponent.length; i++) {
  //       linkComponent[i].addEventListener('click', () => this.modalViewFile(this.languagesService.getValue(options[i])), false);
  //       this.linkComponentsInitialized = true;
  //     }
  //   }

  //   return text;
  // }

  public replaceLinksInText(text: string, options: any[]) {
    // Replaces '[1]link[/1]' with '<a href="option[0]>link</a>"
    const linksArray = text.match(/(\[[1-9]\])(.+?)(\[\/[1-9]\])/g);
    linksArray.forEach((link, index) => {
      const id = Number(link.match(/(\[([1-9])\])(.+?)(\[\/[1-9]\])/)[2]);
      const linkText = link.match(/(\[([1-9])\])(.+?)(\[\/[1-9]\])/)[3];
      text = text.replace(link, '<a class="link-component" target="_blank" href="' + this.languagesService.getValue(options[index]) + '">' + linkText + '</a>');
    });

    return text;
  }

  public generateAllLinkComponents() {
    const linkContainers = document.getElementsByClassName('link-container');
    for (let i = 0; i < linkContainers.length; i++) {
      let text = linkContainers[i].innerHTML;
      // Replaces '[1]link[/1]' with '<a href="option[0]>link</a>"
      const linksArray = text.match(/(\[[1-9]\])(.+?)(\[\/[1-9]\])/g);
      linksArray.forEach(link => {
        const id = Number(link.match(/(\[([1-9])\])(.+?)(\[\/[1-9]\])/)[2]);
        const linkText = link.match(/(\[([1-9])\])(.+?)(\[\/[1-9]\])/)[3];
        text = text.replace(link, '<span class="link-component">' + linkText + '</span>');
      });
      linkContainers[i].innerHTML = text;
    }

    // if (!this.linkComponentsInitialized) {
    //   const linkComponent = document.getElementsByClassName('link-component');
    //   for (let i = 0; i < linkComponent.length; i++) {
    //     linkComponent[i].addEventListener('click', () => this.modalViewFile(this.languagesService.getValue(options[i])), false);
    //     this.linkComponentsInitialized = true;
    //   }
    // }

    //    return text;
  }

  public getLinkInText(text: string) {
    const linksArray = text.match(/(\[[1-9]\])(.+?)(\[\/[1-9]\])/g);
    const linkText = linksArray[0].match(/(\[([1-9])\])(.+?)(\[\/[1-9]\])/)[3];
    return linkText;
  }

  public modalViewFile(file: string) {
    this.dialog.open(ModalViewFileComponent, {
      data: {
        fileUrl: file
      },
      width: '90%',
      height: '95%',
      autoFocus: false
    });
  }

}
